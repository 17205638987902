@import 'colors';
@import 'mixins';

.page__update-notification-wrap {
  visibility: hidden;
  width: 100%;
  background-color: rgba(44, 44, 44, 0.8);
  color: #fff;
  text-align: center;
  border-radius: 3px 2px 0 0;
  padding: 16px;
  position: fixed;
  z-index: 100;
  left: 0;
  bottom: -100px;
  height: 120px;

  &.show {
    visibility: visible;
    @include animation(sw-update-fadein 0.8s forwards);
  }

  a {
    color: $color-p2-light;
    text-decoration: underline;
    @include transition(color .15s ease-out);


    &:hover {
      color: $color-p2-lighter;
    }


  }

}

@include keyframes(sw-update-fadein) {
    from {bottom: -100px}
    to {bottom: 0;}
  };

