/*-

Description: This file includes all Layers icons

Author: Obox Themes
Author URI: http://www.oboxthemes.com/
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
*/

@font-face {
    font-family: 'layers-icons';
    src:    url('./../fonts/icon-fonts/layers-icons.eot?32qo76');
    src:    url('./../fonts/icon-fonts/layers-icons.eot?32qo76#iefix') format('embedded-opentype'),
    url('./../fonts/icon-fonts/layers-icons.woff2?32qo76') format('woff2'),
    url('./../fonts/icon-fonts/layers-icons.ttf?32qo76') format('truetype'),
    url('./../fonts/icon-fonts/layers-icons.woff?32qo76') format('woff'),
    url('./../fonts/icon-fonts/layers-icons.svg?32qo76#layers-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="l-"], [class*=" l-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'layers-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.l-cross:before {
    content: "\e900";
}
.l-tick:before {
    content: "\e901";
}
.l-pencil:before {
    content: "\f040";
}
.l-file-o:before {
    content: "\f016";
}
.l-file-text-o:before {
    content: "\f0f6";
}
.l-calendar-o:before {
    content: "\f133";
}
.l-search:before {
    content: "\f002";
}
.l-shopping-cart:before {
    content: "\f07a";
}
.l-user:before {
    content: "\f008";
}
.l-clock-o:before {
    content: "\f018";
}
.l-tags:before {
    content: "\f02d";
}
.l-comment-o:before {
    content: "\f0e6";
}
.l-folder-open-o:before {
    content: "\f116";
}
.l-menu:before {
    content: "\e601";
}
.l-top-arrow:before {
    content: "\e604";
}
.l-bottom-arrow:before {
    content: "\e605";
}
.l-right-arrow:before {
    content: "\e600";
}
.l-left-arrow:before {
    content: "\e602";
}
.l-close:before {
    content: "\e603";
}

