/**
 * Pro Theme CSS file
 *
 * This file contains all theme CSS styling.
 */

/*-------------------------------*/
/*- LOGO SIZING -----------------*/
/*-------------------------------*/

.layers-logo-small .custom-logo-link img, .layers-logo-small .site-logo-link img, .layers-logo-small .mark img{
	width: auto;
	max-height: 20px;
}

.layers-logo-medium .custom-logo-link img, .layers-logo-medium .site-logo-link img, .layers-logo-medium .mark img{
	width: auto;
	max-height: 40px;
}

.layers-logo-large .custom-logo-link img, .layers-logo-large .site-logo-link img, .layers-logo-large .mark img{
	width: auto;
	max-height: 80px;
}

.layers-logo-massive .custom-logo-link img, .layers-logo-massive .site-logo-link img, .layers-logo-massive .mark img {
	width: auto;
	max-height: 120px;
}


@media only screen and (max-width: 480px){
	.header-site .logo{
		display: block;
		max-width: 80%;
	}

	.layers-logo-massive .site-logo-link + .site-description,
	.layers-logo-large .site-logo-link + .site-description,
	.layers-logo-massive .custom-logo-link + .site-description,
	.layers-logo-large .custom-logo-link + .site-description{
		margin-left: 0;
	}
}


/*-------------------------------*/
/*- POST LIST (SLIDER CAROUSEL) -*/
/*-------------------------------*/

.widget-post-carousel .post-carousel-row-swiper{}
.widget-post-carousel .post-carousel-row-swiper .grid {
	padding: 0px;
	margin: 0;
}
.widget-post-carousel .swiper-container .swiper-wrapper{
	max-width: none;
}
.widget-post-carousel .swiper-container .swiper-slide{
	margin-left: 0;
	margin-right: 0;
	max-width: none;
}
.widget-post-carousel .swiper-pagination{
	position: relative;
	margin: 20px 0;
}
.widget-post-carousel .swiper-slide .overlay{
	display: block;
	width: auto;
	height: auto;
}

/*-------------------------------*/
/*- CALL-TO-ACTION --------------*/
/*-------------------------------*/
.button-collection .button{
	margin: 0 8px;
}
.button-collection.text-left .button{
	margin: 0 8px 0 0;
}
.button-collection.text-right .button{
	margin: 0 0 0 8px;
}

/*-------------------------------*/
/*- SOCIAL ICONS ----------------*/
/*-------------------------------*/

	/*---------------------------*/
	/*- SOCIAL BUTTON SIZES -----*/
	.button-social{
		padding: 8px 15px;
		line-height: 1;
	}
	.button-social.btn-small{
		padding: 8px 15px;
	}
	.button-social.btn-medium{
		padding: 8px 15px;
	}
	.button-social.btn-large{
		padding: 8px 20px;
	}
	.button-social.btn-massive{
		padding: 10px 30px;
	}

	/*---------------------------*/
	/*- SOCIAL ICONS SIZES ------*/
	.button-social i{
		width: 1.6rem;
		font-size: 1.4rem;
	}
	.button-social.btn-medium i{
		width: 2.0rem;
		font-size: 1.8rem;
	}
	.button-social.btn-large i{
		width: 2.4rem;
		font-size: 2.2rem;
	}
	.button-social.btn-massive i{
		width: 2.2rem;
		font-size: 2.2rem;
	}

	/*---------------------------*/
	/*- SOCIAL NETWORK NAME -----*/
	.button-social .network-name{
		padding-left: 8px;
		margin-left: 2px;
		border-left: 1px solid rgba(255, 255, 255, 0.3);
		text-transform: capitalize;
	}


/*-------------------------------*/
/*- TABS ------------------------*/
/*-------------------------------*/

.layers-pro-tabs {

}
.layers-pro-tabs .nav-tabs.no-background {
	background: none;
}

	/*---------------------------*/
	/*- TAB CONTENT -------------*/
	.tab-content{}
	.tab-content > .row{
		display: none;
	}
	.tab-content > .row:first-child{
		display: block;
	}


/*-------------------------------*/
/*- ACCORDION -------------------*/
/*-------------------------------*/

.accordion-list {}
.accordion-list > li {
	margin-bottom: 1px;
}
.accordion-list > li > a {
	border-bottom: 1px solid transparent;
	display: block;
	transition: .1s;
}
.accordion-list > li.active > a {}
.accordion-list > li:first-child > a {
	border-top: 1px solid transparent;
}
.accordion-list > li > a:before {
	content: "\e600";
	font-family: 'layers-icons';
	color: rgba(0,0,0,0.25);
	font-size: .8em;
	margin: 0 0.3em 0 0;
	font-weight: bold;
	transition: .1s;
	display: inline-block;
}
.accordion-list > li.active > a:before {
	transition: .2s;
	transform: rotate(90deg);
}
.accordion-list > li > section {
	border-bottom: 1px solid transparent;
	position: absolute;
	visibility: hidden;
}
.accordion-item-styling .accordion-list > li > a {
	border-bottom-color: rgba(0,0,0,0.05);
}
.accordion-item-styling .accordion-list > li > a:hover{
	background: rgba(0, 0, 0, 0.01);
}
.accordion-item-styling .accordion-list > li:first-child > a {
	border-top-color: rgba(0,0,0,0.05);
}
.accordion-item-styling .accordion-list > li > section {
	border-bottom-color: rgba(0,0,0,0.05);
}

/*---------------------------*/
/*- ACCORDION BUTTON SIZES --*/
.accordion-button{
	padding: 8px 10px;
}
.accordion-button.accordion-small{
	padding: 4px 8px;
	font-size: 1.2rem;
}
.accordion-button.accordion-small:before{
	font-size: .9rem;
	margin: 0 .4em 0 0;
}
.accordion-button.accordion-large{
	padding: 10px 18px;
	font-size: 1.8rem;
}
.accordion-button.accordion-large:before{
	font-size: 1.4rem;
	margin: 0 0.5em 0 0;
}
.accordion-button.accordion-massive{
	padding: 15px 21px;
	font-size: 2.0rem;
}
.accordion-button.accordion-massive:before{
	font-size: 1.6rem;
	margin: 0 .5em 0 0;
}


/*---------------------------*/
/*- SLIDER VIDEO BG  --------*/
.swiper-slide video{
	position: absolute;
	top: 0;
	left: 0;
	min-width: 100%;
	min-height: 100%;
	z-index: auto;
	background-size: cover;
	object-fit: cover;
	-webkit-object-fit: cover;
}
.swiper-slide video source{
	width: 100%;
	height: 100%;
}
.layerspro-slider-video {
	position: absolute;
	z-index: 0;
	width: 100%;
	height: 100%;
}

.layerspro-slider-video iframe{
	max-width: none;
	position: absolute;
	display: block;
}

.layerspro-slider-video.layers-slider-video-ultra-wide iframe{
	width: 100%;
	height: 350%;
	top: -125%;
	left: 0;
}

.layerspro-slider-video.layers-slider-video-super-wide iframe{
	width: 100%;
	height: 250%;
	top: -75%;
	left: 0;
}

.layerspro-slider-video.layers-slider-video-wide iframe{
	width: 100%;
	height: 200%;
	top: -50%;
	left: 0;
}

.layerspro-slider-video.layers-slider-video-square iframe{
	width: 160%;
	height: 100%;
	top: 0;
	left: -30%;
}

.layerspro-slider-video.layers-slider-video-tall iframe{
	width: 250%;
	height: 100%;
	top: 0;
	left: -75%;
}

.layerspro-slider-video.layers-slider-video-super-tall iframe {
	width: 400%;
	height: 100%;
	top: 0;
	left: -150%;
}

/*---------------------------*/
/*- SEARCH ------------------*/

	/*-----------------------*/
	/*- SEARCH HEADER ICON --*/
	.header-search{
		display: -webkit-flex;;
		display: flex;
		line-height: 40px;
		margin-left: 10px;
	}
	.header-search + .header-cart{
		margin-left: 20px;
	}
	.header-center .header-search,
	.nav-clear .header-search{
		line-height: 20px;
	}

		/*-------------------------*/
		/*- HEADER SIDEBAR SEARCH -*/
		.header-side-wrapper .header-search{
			margin-left: 0;
		}

	/*----------------------------*/
	/*- SEARCH OVERLAY INTERFACE -*/
	.search-interface-overlay{
		visibility: hidden;
		opacity: 0;

		display: flex;
		align-items: center;
		position: fixed;
		top: 0;
		left: -150%;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0);
		z-index: 10000;
		transition:
			all 0s 0s cubic-bezier(0.32, 0, 0, 1),
			transform .3s .15s cubic-bezier(0.32, 0, 0, 1),
			background .3s 0s cubic-bezier(0.32, 0, 0, 1);

		transform: scale(2);
	}
	.search-interface-holder{
		width: 100%;
		max-width: 1040px;
		margin: auto;
		padding: 0 20px;
	}
	.search-interface-overlay .search-text{
		opacity: 0;
		font-size: 2rem;
		color: rgba(255,255,255,0.5);

		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
		transition: 0.3s all ease-in-out 0.4s;
	}
	.search-interface-overlay .search-field{
		opacity: 0;
		display: block;
		width: 100%;
		max-width: 100%;
		height: auto;
		padding: 0 0 10px;
		background: none;
		border: 0;
		border-bottom: 2px solid transparent;
		color: #fff;
		font-size: 6rem;
		line-height: 1.4;

		-webkit-transform: translate3d(0, -15px, 0);
		transform: translate3d(0, -15px, 0);
		transition: border-color 0.3s ease-in-out 0.4s,
					opacity 0.3s ease-in-out 0.2s,
					transform 0.3s ease-in-out 0.2s;
	}
	.search-interface-overlay .search-submit {
		display: none;
	}

	.search-close{
		opacity: 0;
		position: absolute;
		right: 60px;
		top: 60px;
		width: 38px;
		padding: 8px;
		background: rgba(255,255,255,0.75);
		border-radius: 100px;
		border: 2px solid transparent;
		text-align: center;
		color: rgba(0, 0, 0, 0.7);
		font-size: 1.4rem;
		line-height: 1.3;

		/*-webkit-transform: translate3d(0, -15px, 0);
		transform: translate3d(0, -15px, 0);*/
		transition: opacity 0.3s ease-in-out 0.25s,
					transform 0.3s ease-in-out 0.25s;
	}
	.search-close:hover{
		color: rgba(255, 255, 255, 1);
		background: rgba(255, 255, 255, 0.25);
		border-color: rgba(255, 255, 255,0.5);
	}
	.search-close:active{
		background: rgba(255, 255, 255, 0.1);
		border-color: transparent;
	}

	/*---------------------------*/
	/*- PLAY IN/OUT ANIMATIONS --*/
	.search-interface-play-in{
		visibility: visible;
		opacity: 1;
		left: 0;
		background: rgba(0, 0, 0, 0.84);
		transform: scale(1);
	}
	.search-interface-play-in .search-text,
	.search-interface-play-in .search-field,
	.search-interface-play-in .search-close{
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
	.search-interface-play-in .search-field{
		border-bottom-color: rgba(255, 255, 255, 1);
	}

	.search-interface-play-out{
		visibility: hidden;
		opacity: 0;
		left: -150%;
		background: rgba(0, 0, 0, 0);
		transition:
			all 0s .5s cubic-bezier(0.32, 0, 0, 1),
			opacity .2s .3s cubic-bezier(0.32, 0, 0, 1),
			background .2s .3s cubic-bezier(0.32, 0, 0, 1);
	}

	.search-interface-play-out .search-text,
	.search-interface-play-out .search-close{
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
		transition: opacity 0.45s ease-in-out 0s,
					transform 0.45s ease-in-out 0s;
	}
	.search-interface-play-out .search-field{
		border-bottom-color: transparent;
		-webkit-transform: translate3d(0, -10px, 0);
		transform: translate3d(0, -10px, 0);
		transition: border-bottom-color 0.2s ease-in-out 0.05s,
					opacity 0.4s ease-in-out 0.05s,
					transform 0.4s ease-in-out 0.05s;
	}


/*--------------------------------------------------------------*/
/*- SMALL SCREENS ----------------------------------------------*/
/*--------------------------------------------------------------*/
@media only screen and (max-width: 480px){

	/*---------------------------*/
	/*- SEARCH ------------------*/
	.header-search,
	.search-interface-overlay{
		display: none;
	}

	/*---------------------------*/
	/*- HIDE ON MOBILE ----------*/
	.hide-phone{
		display: none;
	}
/*
	.layerspro-slider-video iframe{
		width: 300%;
		height: 350%;
		left: -100%;
		top: -125%;
	}
*/
}

/*--------------------------------------------------------------*/
/*- TABLET SCREENS ---------------------------------------------*/
/*--------------------------------------------------------------*/
@media only screen and (min-width: 481px) and (max-width: 768px){

	/*---------------------------*/
	/*- HIDE ON TABLET ----------*/
	.hide-tablet{
		display: none;
	}

}

/*--------------------------------------------------------------*/
/*- DESKTOP SCREENS --------------------------------------------*/
/*--------------------------------------------------------------*/
@media only screen and (min-width: 769px){

	/*---------------------------*/
	/*- HIDE ON DESKTOP ---------*/
	.hide-desktop{
		display: none;
	}

}