@import "../colors";

/*
Theme Name: FUTR
Description: FUTR - Show your projects, beautifully.
Author: ThemePioneer
Template: layerswp
Version: 1.2.4
License: GPL 2.0
License URI: http://www.gnu.org/licenses/gpl-2.0.html


/* =TABLE OF CONTENTS
--------------------------------------------------------------
 1. Header styles
 2. Sidebar styles
 3. Font styles
 4. Preloader styles
 5. Selection styles
 6. Contact form styles
 7. Button styles
 8. Content styles
 9. Portfolio styles
   9.1 General portfolio styles
   9.2 Portfolio hover style 1
   9.3 Portfolio hover style 2
   9.4 Portfolio hover style 3
   9.5 Portfolio hover style 4
 10. Responsive styles
   10.1 Small screen specific styles
   10.2 Small to medium screen specific styles
   10.3 Desktop specific styles
 11. Footer styles
*/

/* 1. =Header styles
-------------------------------------------------------------- */

.container {
  max-width: 1170px;
}

.site-logo-link img {
  max-height: 70px;
}

.nav-horizontal li {
  margin: 0 22px;
}

.nav-horizontal li a {
  font-weight: 400;
}

.header-site .nav #nav li a {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  color: #808080;
}

.sub-menu li a:hover {
  background-color: $color-bg;
}

div[class*=" off-canvas-"] li a {
  color: rgba(25, 25, 25, 0.8);
}

/* 2. =Sidebar styles
-------------------------------------------------------------- */

.sidebar .well {
  background-color: #f7f7f7;
}

/* 3. =Font styles
-------------------------------------------------------------- */

.fa {
  color: $color-p1-normal;
}

.text-left .heading:after {
  margin-left: 0px;
  margin-right: 0px;
}

.text-right .heading:after {
  margin-left: auto;
  margin-right: 0px;
}

/* 4. =Preloader styles
-------------------------------------------------------------- */

.loaderouter {
  display: table;
  position: absolute;
  height: 100%;
  width: 100%;
}

.preloadmiddle {
  display: table-cell;
  vertical-align: middle;
}

.preloadinner {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  margin-top: 10%;
}

.preloadinner h2 {
  text-align: center;
  word-break: break-all;
}

/* 5. =Selection styles
-------------------------------------------------------------- */

::selection {
  background: $color-p1-light; /* Sets the default selection color for browsers */
}
::-moz-selection {
  background: $color-p1-light; /* Sets the default selection color for gecko browsers  */
}

/* 6. =Contact form 7 styles
-------------------------------------------------------------- */

.wpcf7-form p {
  font-size: 16px;
  font-weight: normal;
}

.wpcf7 input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea {
  border: none;
  box-shadow: inset 0 -1px 0 #c7c7c7;
}

/* 7. =Button styles.
-------------------------------------------------------------- */
input[type="submit"] {
  background-color: $color-p1-normal;
  border-radius: 5px;
  padding: 10px 20px;
}

input[type="submit"]:hover {
  background-color: $color-p1-normal;
  color: #fff;
}

.button {
  background-color: $color-p1-normal;
  border-radius: 0px;
  border-bottom-right-radius: 10px;
  padding: 10px 20px;
}

.swiper-slide .button {
  border-radius: 0px;
}

.swiper-centered-content {
  display: flex;
  align-items: center;
}

/* 8. =Content styles.
-------------------------------------------------------------- */

body {
  padding: 20px;
  background-color: #f1f1f1;
}
.content-vertical {
  &.bgdark {
    background-color: #f9f9f9;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.bgTransparent {
    background-color: #00000080;
    background-repeat: no-repeat;
    background-position: center;

    i.fa {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.content-vertical-massive {
  padding-top: 5%;
  padding-bottom: 3%;

  &.bgdark {
    background-color: #f9f9f9;
    background-repeat: no-repeat;
    background-position: center;
  }


  &.bgTransparent {
    background-color: #00000080;
    background-repeat: no-repeat;
    background-position: center;

    i.fa {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(125, 125, 125, 0.4),
    rgba(0, 0, 0, 0)
  );
}

#back-to-top {
  position: absolute;
  display: none;
}

.services .media-body {
  padding: 10%;
}

.single-post .content-main {
  padding: 3% 20px;
}

.media-body .heading .fa {
  display: block;
  margin-top: 2rem;
}

.archive .content-main,
.category .content-main {
  padding: 1% 20px;
}

/* 9. =Portfolio styles
-------------------------------------------------------------- */

#portfolio {
  font-size: 0;
}

.futr-portfolio-widget .span-12 {
  width: 100%;
}

.futr-portfolio-widget .span-6 {
  width: 50%;
}

.portfolio-list.with-overlay .excerpt {
  display: block;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
}

#filters,
.futr-portfolio-widget .futr-isotope-filter {
  text-align: center;
}

.futr-portfolio-widget [class*="column"] {
  margin: 0;
  padding: 5px;
}

.futr-isotope-filter li a {
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  font-weight: 400;
}

.futr-isotope-filter li a {
  padding: 2px;
}

.futr-isotope-filter li a.active,
.futr-isotope-filter li a:hover {
  border-bottom: 1px solid $color-p1-normal;
}

#filters li,
.futr-portfolio-widget .futr-isotope-filter li {
  display: inline-block;
  padding: 20px;
}

#filters li,
.futr-portfolio-widget .futr-isotope-filter li:first-child {
  padding-left: 0px;
}

.futr-portfolio .portfolio-item {
  display: inline-block;
}

.futr-portfolio .portfolio-item .thumb {
  position: relative;
  display: block;
}

.futr-portfolio .portfolio-item .project-holder {
  width: 100%;
  height: 100%;
  display: table;
}

.futr-portfolio-widget .masonry-layout .with-overlay .thumbnail-body {
  padding: 0px;
}

.container.masonry-layout {
  position: relative;
  padding: 0;
  max-width: 1170px;
}

.futr-portfolio .portfolio-item .project-desc {
  vertical-align: middle;
  display: table-cell;
  text-align: center;
}

.futr-portfolio .portfolio-item .project-holder {
  width: 100%;
  height: 100%;
  display: table;
}

.futr_portfolio .content-main {
  padding: 1em 0px;
}

.futr_portfolio .section-title {
  text-align: center;
  position: relative;
  margin-bottom: 2em;
}

.futr_portfolio .thumbnail img {
  display: block;
  margin: auto;
}

.futr_portfolio .story blockquote {
  //background-color: #fff;
  color: black;
  border-bottom: 3px solid $color-p1-normal;
}

.futr_portfolio.with-sidebar article {
  width: 70%;
  float: left;
}

.futr_portfolio.with-sidebar .project-sidebar {
  width: 300px;
  float: left;
  margin-left: 30px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  border-top: 1px solid rgba(108, 11, 20, 0.27);
  border-bottom: 1px solid rgba(108, 11, 20, 0.27);
  top: 0px;
}

.futr_portfolio.with-sidebar .project-sidebar.scroll {
  position: fixed;

}

.futr_portfolio.with-sidebar .project-side-inner {
  padding: 20px;
  color: #717171;
  font-size: 14px;
}

.futr_portfolio.with-sidebar .project-side-inner .client-name {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.futr_portfolio.with-sidebar .project-side-inner span {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
}

/* Hover style 1 */

.futr-portfolio.hover-style-1 .portfolio-item .project-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.futr-portfolio.hover-style-1 .portfolio-item .thumbnail:hover .project-hover {
  opacity: 1;
}

.futr-portfolio.hover-style-1 .portfolio-item .project-desc .project-title h2 {
  font-size: 26px;
  color: #fff;
  margin: auto;
  text-align: center;
  transform: translateY(-40px);
  -webkit-transform: translateY(-40px);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  opacity: 0;
}

.futr-portfolio.hover-style-1
  .portfolio-item
  .thumbnail:hover
  .project-desc
  .project-title
  h2 {
  opacity: 1;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
}

.futr-portfolio.hover-style-1 .portfolio-item .project-desc .project-tag p {
  font-size: 16px;
  color: #fff;
  text-align: center;
  transform: translateY(20px);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  opacity: 0;
}

.futr-portfolio.hover-style-1
  .portfolio-item
  .thumbnail:hover
  .project-desc
  .project-tag
  p {
  opacity: 1;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
}

.futr-portfolio.hover-style-1
  .portfolio-item
  .project-desc
  .project-tag
  p:first-letter {
  text-transform: uppercase;
}

/* End hover style 1 */

/* Hover style 2 */

.futr-portfolio.hover-style-2 .portfolio-item .thumbnail:hover .project-desc {
  opacity: 1;
}

.futr-portfolio.hover-style-2 .portfolio-item .project-hover {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  width: auto;
  height: auto;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.futr-portfolio.hover-style-2 .portfolio-item .thumbnail:hover .project-hover {
  opacity: 1;
}

.futr-portfolio.hover-style-2 .portfolio-item .project-desc .project-title h2 {
  font-size: 26px;
  color: #222222;
  margin: auto;
  text-align: center;
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  opacity: 0;
  transform: translateX(-40px);
  -webkit-transform: translateX(-40px);
}

.futr-portfolio.hover-style-2
  .portfolio-item
  .thumbnail:hover
  .project-desc
  .project-title
  h2 {
  opacity: 1;
  transform: translateX(0px);
}

.futr-portfolio.hover-style-2 .portfolio-item .project-desc .project-tag p {
  font-size: 16px;
  color: #222222;
  text-align: center;
  transform: translateX(-40px);
  -webkit-transform: translateX(-40px);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
  opacity: 0;
}

.futr-portfolio.hover-style-2
  .portfolio-item
  .thumbnail:hover
  .project-desc
  .project-tag
  p {
  opacity: 1;
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
}

.futr-portfolio.hover-style-2
  .portfolio-item
  .project-desc
  .project-tag
  p:first-letter {
  text-transform: uppercase;
}

/* End hover style 2 */

/* Start hover style 3 */

.futr-portfolio.hover-style-3 .portfolio-item .thumbnail:hover .project-desc {
  opacity: 1;
}

.futr-portfolio.hover-style-3 .portfolio-item .project-hover {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: auto;
  height: auto;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.futr-portfolio.hover-style-3 .portfolio-item .thumbnail:hover .project-hover {
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
}

.futr-portfolio.hover-style-3 .portfolio-item .thumbnail:hover .project-hover {
  opacity: 1;
}

.futr-portfolio.hover-style-3
  .portfolio-item
  .thumbnail
  .attachment-post-thumbnail {
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.futr-portfolio.hover-style-3
  .portfolio-item
  .thumbnail:hover
  .attachment-post-thumbnail {
  transform: scale(1.2);
}

.futr-portfolio.hover-style-3 .portfolio-item .project-desc .project-title h2 {
  font-size: 26px;
  color: #222222;
  margin: auto;
  text-align: center;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  opacity: 0;
  transform: translateX(-40px);
  -webkit-transform: translateX(-40px);
}

.futr-portfolio.hover-style-3
  .portfolio-item
  .thumbnail:hover
  .project-desc
  .project-title
  h2 {
  opacity: 1;
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
}

.futr-portfolio.hover-style-3 .portfolio-item .project-desc .project-tag p {
  font-size: 16px;
  color: #222222;
  text-align: center;
  transform: translateX(40px);
  -webkit-transform: translateX(40px);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition-delay: 0.1s;
  -webkit-transition-delay: 0.1s;
  opacity: 0;
}

.futr-portfolio.hover-style-3
  .portfolio-item
  .thumbnail:hover
  .project-desc
  .project-tag
  p {
  opacity: 1;
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
}

.futr-portfolio.hover-style-3
  .portfolio-item
  .project-desc
  .project-tag
  p:first-letter {
  text-transform: uppercase;
}

/* End hover style 3 */

/* Start hover style 4 */

.futr-portfolio.hover-style-4 .portfolio-item .project-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.futr-portfolio.hover-style-4 .portfolio-item .thumbnail:hover .project-hover {
  opacity: 1;
}

.futr-portfolio.hover-style-4 .portfolio-item .project-desc .project-title h2 {
  font-size: 26px;
  color: #fff;
  margin: auto;
  text-align: center;
  transform: translateY(20px);
  -webkit-transform: translateY(20px);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  opacity: 0;
  font-weight: bold;
}

.futr-portfolio.hover-style-4
  .portfolio-item
  .project-desc
  .portfolio-separator {
  display: block;
  margin-top: 3px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  background: #27ae60;
  width: 60px;
  height: 2px;
}

.futr-portfolio.hover-style-4
  .portfolio-item
  .thumbnail:hover
  .project-desc
  .project-title
  h2 {
  opacity: 1;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
}

.futr-portfolio.hover-style-4 .portfolio-item .project-desc .project-tag p {
  font-size: 16px;
  color: #fff;
  text-align: center;
  transform: translateY(20px);
  -webkit-transform: translateY(20px);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  opacity: 0;
  font-style: italic;
}

.futr-portfolio.hover-style-4
  .portfolio-item
  .thumbnail:hover
  .project-desc
  .project-tag
  p {
  opacity: 1;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
}

.futr-portfolio.hover-style-4
  .portfolio-item
  .project-desc
  .project-tag
  p:first-letter {
  text-transform: uppercase;
}

.futr-portfolio-widget .nav-pills a {
  border: 0px;
}

/* End hover style 4 */

/* 10. =Responsive styles
-------------------------------------------------------------- */

/* Small screen specific CSS */
@media (max-width: 480px) {
  section[class*=" off-canvas-"] li a,
  .button {
    color: #5f5f5f;
  }

  .futr-portfolio-widget .button,
  .futr-portfolio-widget .heading,
  .futr-portfolio-widget .excerpt {
    padding-left: 10px;
    padding-right: 10px;
  }

  #filters li,
  .futr-portfolio-widget .futr-isotope-filter li {
    padding: 8px;
  }

  #portfolio-wrapper .portfolio-item,
  .futr-portfolio-widget .masonry-layout .portfolio-list {
    max-width: 100%;
    height: auto;
  }

  .button {
    color: #fff;
  }

  body {
    padding: 10px;
  }
}

/* Any screen smaller then desktop CSS */
@media (max-width: 768px) {
  .portfolio-list.with-overlay .excerpt {
    color: #5f5f5f;
  }

  .futr-portfolio-widget .portfolio-overlay .overlay {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .futr_portfolio .story {
    padding: 1em;
  }

  .single-previous,
  .single-next {
    font-size: 4em;
  }

  .futr-single-nav {
    min-height: 60px;
    position: relative;
  }

  .futr-single-nav .left a {
    position: absolute;
    left: 25px;
    right: auto;
    margin-top: -6%;
  }

  .futr-single-nav .right a {
    position: absolute;
    right: 25px;
    left: auto;
    margin-top: -6%;
  }

  .footer-site {
    padding-top: 30px;
  }

  .futr_portfolio.with-sidebar .project-sidebar {
    width: 90%;
    margin: auto;
    float: none;
    position: relative;
    top: auto;
    height: auto;
  }

  .futr_portfolio.with-sidebar article {
    width: 100%;
    float: none;
  }

  .futr_portfolio .project-sidebar .client-logo img {
    display: block;
    margin: auto;
  }
}

/* Desktop screen specific CSS */
@media (min-width: 769px) {
  .header-center .nav .menu {
    margin: 0px;
    padding: 15px 0;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
  }

  .futr-portfolio.col-2 .portfolio-item {
    width: 50%;
  }

  .futr-portfolio.col-3 .portfolio-item {
    width: 33.3333333%;
  }

  .futr-portfolio.col-4 .portfolio-item {
    width: 25%;
  }

  body {
    padding: 20px;
  }

  .header-site .container {
    padding: 10px;
  }

  .futr-single-nav .arrows {
    font-size: 5em;
    position: fixed;
    top: 45%;
  }

  .futr-single-nav .fa {
    color: $color-p1-normal;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
  }

  .futr-single-nav .left {
    left: 25px;
    right: auto;
  }

  .futr-single-nav .right {
    right: 25px;
    left: auto;
  }

  .futr-single-nav .fa:hover {
    color: $color-p1-light;
  }
  .single-post .post .pull-right.sidebar {
    margin-top: 113px;
  }
}

@media (max-width: 1240px) {
  .futr_portfolio.with-sidebar .project-sidebar {
    width: 90%;
    margin: auto;
    float: none;
    position: relative;
    top: auto;
    height: auto;
  }

  .futr_portfolio.with-sidebar article {
    width: 90%;
    float: none;
    position: relative;
    display: block;
    margin: auto;
  }

  .futr_portfolio .project-sidebar .client-logo img {
    display: block;
    margin: auto;
  }
}

/* 11. =Footer styles
-------------------------------------------------------------- */

.footer-site .widget .fa {
  color: #616161;
  font-size: 2em;
  margin-right: 10px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.footer-site .widget .fa-facebook:hover {
  color: #3b5998;
}

.footer-site .widget .fa-twitter:hover {
  color: #55acee;
}

.footer-site .widget .fa-google-plus:hover {
  color: #dc4b3e;
}
.footer-site .widget .fa-soundcloud:hover {
  color: #ff5500;
}
.footer-site .widget .fa-linkedin:hover {
  color: #0073b1;
}
.footer-site .widget .fa-instagram:hover {
  color: #262626;
}
.footer-site .widget .fa-github:hover {
  color: #010101;
}

/* ------- END FUTR CSS ------- */
