@import "../initials";

.new-post {
  font-family: 'Raleway', sans-serif;

  label {
    font-weight: normal;
  }
}

.new-post__abstract {
  >textarea {
    @include default-textarea;
  }
}

.new-post__text {
  >textarea {
    @include default-textarea;
    min-height: 300px;
  }
}

.new-post__input {
  @include default-input;
}

.new-post__post-text-label {

  margin-top: 20px;
  margin-bottom: 5px;

  >button {
    float: left;
  }
  >span {
    float: right;
    display: inline-block;
    position: relative;
    top: 15px;
    font-weight: bold;
  }
}

.new-post__submit-and-error-wrap {
  margin: 20px 0 50px 0;

  >button {
    float: right;
  }
}

.new-post__error {
  float: left;
  min-height: 34px;
  display: inline-block;
  width: calc(100% - 80px);
  font-size: calc(16rem / 14);
}


.new-post__static-checkbox {
  > span {
    vertical-align: text-bottom;
  }
}

.wysiwyg-container {
  button, i {
    color: $color-p2-light;
  }
}