$color-p1-light: #bf001e;
$color-p1-normal: #811518;
$color-p1-dark: #59000E;


$color-p2-light: #f5f5f5;
$color-p2-lighter: #cdcdcd;
$color-p2-normal: #9B9B9B;
$color-p2-dark: #6f6f6f;

$color-p3-light: #444444;
$color-p3-normal: #3b3b3b;
$color-p3-dark: #111;

$color-anchor-default: $color-p1-normal;
$color-anchor-hover: $color-p3-dark;

$color-bg: #fff;
/*$color-p1-normal: #6C0B14;
$color-p1-light: #A76E73;

$color-bg: $color-p1-normal;


 */