.tbh-timeline {
  margin-top: 150px;
  margin-bottom: 140px;

  @media (max-width: $mobile-lg-max) {
    margin-left: 30px;
    margin-top: 0px;
    margin-bottom: 50px;
  }
}

.tbh-timeline--container {
}

.tbh-timeline--items-container {
  position: relative;
  //background-color: rgba(0, 23, 22, 0.1);
}
.tbh-timeline--items {
  display: flex;
  justify-content: space-evenly;
  @media (max-width: $mobile-lg-max) {
    flex-direction: column;
  }

  &:before {
    content: " ";
    height: 1px;
    background-color: $color-p1-dark;
    width: 72%;
    display: block;
    top: 50%;
    left: 14%;
    position: absolute;
    z-index: -1;

    @media (max-width: $mobile-lg-max) {
      width: 1px;
      height: 80%;
      top: 10px;
      left: 28px;
    }
  }
}
.tbh-timeline--item-container {
  position: relative;

  @media (max-width: $mobile-lg-max) {
    height: 150px;
    display: flex;
  }
}

.tbh-timeline--item {
}

.tbh-timeline--item-preview {
  transition: transform 400ms ease-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;

  &:hover {
    transform: scale(1.5);
  }

  i {
    padding: 5px;
    font-size: 3rem;
    background-color: white;
  }
}

.tbh-timeline--item-container.active .tbh-timeline--item-preview {
  transform: scale(2);

  @media (max-width: $mobile-lg-max) {
    transform: scale(1.3);
  }
  //padding: 10px;
  border: 1px solid $color-p1-normal;
  background-color: white;
  border-radius: 50%;
}

.tbh-timeline--item-container:nth-child(2n)
  .tbh-timeline--item-description-container {
  top: 90px;
  @media (max-width: $mobile-lg-max) {
    top: unset;
  }
}
.tbh-timeline--item-description-container {
  //display: none;
  position: absolute;
  top: -150px;
  text-align: center;
  width: 250px;
  opacity: 0.5;
  transition: opacity 400ms ease-in;

  @media (max-width: $mobile-lg-max) {
    position: relative;
    text-align: center;
    width: 60%;
    top: unset;
    transform: translate(0, 0);
    margin-top: 10px;
    margin-left: 14%;
    display: block;
  }
  transform: translate(-100px, 0);

  h1 {
    font-size: 1.5em;
    @media (max-width: $mobile-lg-max) {
      color: $color-p3-light;
    }
  }

  p {
    font-size: 1em;
  }
}

.tbh-timeline--item-container.active .tbh-timeline--item-description-container {
  display: block;

  opacity: 1;

  h1 {
    @media (max-width: $mobile-lg-max) {
      color: $color-p1-normal;
    }
  }
}
