/*-

Description: This is the main Layers structure stylesheet.

Use this file as a reference for Custom CSS or to build
a child theme color scheme. Do not edit this file directly -
it is not loaded into the theme!!

Author: Obox Themes
Author URI: http://www.oboxthemes.com/
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html

-*/

/*-------------------------------*/
/*- RESET -----------------------*/
/*- http://richclarkdesign.com --*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  list-style: none;
}
body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
nav ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
a {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}
ins {
  text-decoration: none;
}
mark {
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
}

/*-------------------------------*/
/*- CLEARFIX --------------------*/
/*-------------------------------*/
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  *zoom: 1;
}

.row:before,
.row:after {
  content: " ";
  display: table;
}
.row:after {
  clear: both;
}
.row {
  *zoom: 1;
}

.button-group:before,
.button-group:after {
  content: " ";
  display: table;
}
.button-group:after {
  clear: both;
}
.button-group {
  *zoom: 1;
}

.form-row:before,
.form-row:after {
  content: " ";
  display: table;
}
.form-row:after {
  clear: both;
}
.form-row {
  *zoom: 1;
}

.title:before,
.title:after {
  content: " ";
  display: table;
}
.title:after {
  clear: both;
}
.title {
  *zoom: 1;
}

.story:before,
.story:after {
  content: " ";
  display: table;
}
.story:after {
  clear: both;
}
.story {
  *zoom: 1;
}

/*nav:before, nav:after{content: " "; display: table;}
nav:after{clear: both;}
nav{*zoom: 1;}*/

/*-------------------------------*/
/*- DEFAULTS --------------------*/
/*-------------------------------*/
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  overflow-x: hidden;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
body {
  overflow-x: hidden;
  font-size: 1.5rem;
  line-height: 1.5;
  background-color: #454545;
  color: rgba(0, 0, 0, 0.55);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
}

code {
  display: inline;
  padding: 4px 6px;
  background-color: #eee;
  color: #8f8f8f;
  font-size: 85%;
  font-weight: 700;
  font-family: "Courier New", Courier, monospace;
}

pre {
  padding: 20px 20px 0;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #323232;
  font-size: 85%;
  font-weight: 700;
  font-family: "Courier New", Courier, monospace;
  text-transform: initial;
}
strong {
  font-weight: 700;
}
small {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.2rem;
}
a {
  color: rgba(0, 0, 0, 0.75);
}
a:hover {
  color: rgba(0, 0, 0, 1);
}
img {
  max-width: 100%;
  height: auto;
}
iframe,
embed,
object {
  max-width: 100%;
}

.desktop-collapse {
  display: none;
}
.cursor-clickable {
  cursor: pointer;
}
.cursor-resizable {
  cursor: ew-resize;
}
.cursor-grabable {
  //	cursor: -webkit-grab;
  //	cursor: -moz-grab;
}
.hide {
  display: none;
}
.animate {
  transition: all 0.2s ease-in-out;
}
.post-date {
  display: block;
  font-size: 1.2rem;
}
.excerpt {
  word-wrap: break-word;
}

/*--------------------------------------------------------------*/
/*- ALL SCREENS ------------------------------------------------*/
/*--------------------------------------------------------------*/

/*---------------------------*/
/*- GRIDS -------------------*/
.grid {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  position: relative;
}
.container .grid {
  margin-left: -10px;
  margin-right: -10px;
}

/*-----------------------*/
/*- COLUMN SETUP --------*/
[class*="column"] {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  position: relative;
  max-width: calc(100% - 20px);
}
.column-flush {
  margin: 0;
  position: relative;
  max-width: 100%;
}

/*-----------------------*/
/*- FALLBACK ------------*/
.row .column {
  float: left;
}

.layers-masonry-column .grid {
  -webkit-flex-direction: column;
  flex-direction: column;
}

.layers-masonry-column.bgTransparent {
  background-color: #00000080;
  color: rgba(255, 255, 255, 0.8);
  padding: 10px;

  h2 {
    color: rgba(255, 255, 255, 0.8);
  }
}


.span-12 {
  min-width: calc(((100% / 12) * 12) - 20px);
}
.column-flush.span-12 {
  min-width: calc((100% / 12) * 12);
}

.middled,
.row .column.middled {
  float: none;
  margin: auto;
}
.no-gutter {
  margin-right: 0 !important;
}

/*---------------------------*/
/*- MASONRY -----------------*/
.container.list-masonry {
  max-width: 1040px;
  padding: 0 10px;
  position: relative;
}
.container.list-masonry .grid.loaded > *:not(.masonry-loading) {
  opacity: 0;
}
.container.list-masonry .grid.loaded > *:not(.masonry-loading) {
  opacity: 1;
}
.container.list-masonry .masonry-loading {
  width: 48px;
  height: 48px;
  margin: -25px 0 0 -25px;
  position: absolute;
  left: 50%;
  top: 50px;
  background: rgba(0, 0, 0, 0.7) "url('/images/spinner.gif')" no-repeat center
    center;
  border-radius: 100%;
  overflow: hidden;
  opacity: 0;
}
.list-masonry .grid {
  margin-left: 10px;
  margin-right: -10px;
}
.list-masonry .column {
  margin-left: 0;
  margin-right: 0;
}
.container-full-width.list-masonry .grid {
  margin-left: 0px;
  margin-right: 0px;
}

/*-------------------------------*/
/*- CONTENT CONTAINERS ----------*/

/*---------------------------*/
/*- WRAPPERS ----------------*/
#wrapper-site,
.wrapper-site {
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
  transition: left 0.3s ease-in-out 0s, opacity 0.2s ease-in-out 0.1s,
    top 0.3s ease-in-out 0s, width 0.3s ease-in-out 0s;
  z-index: 2;
  opacity: 1;
}

.wrapper-content {
  position: relative;
}

/*---------------------------*/
/*- CONTAINER ---------------*/
.container {
  max-width: 1040px;
  margin: auto;
  padding: 0 20px;
}

.wrapper-site.container {
  max-width: 1140px;
}

/*---------------------------*/
/*- CONTENT PADDING ---------*/
.content {
  padding: 20px;
}
.content-main {
  padding: 6% 20px;
}
.content-small {
  padding: 10px;
}
.content-large {
  padding: 3%;
}
.content-extra-large {
  padding: 4%;
}
.content-massive {
  padding: 6%;
}

/*---------------------------*/
/*- VERTICAL PADDING --------*/
.content-vertical {
  padding-top: 2%;
  padding-bottom: 2%;
}
.content-vertical-large {
  padding-top: 3%;
  padding-bottom: 3%;
}
.content-vertical-extra-large {
  padding-top: 4%;
  padding-bottom: 4%;
}
.content-vertical-massive {
  padding-top: 6%;
  padding-bottom: 4%;
}

.inset-top {
  padding-top: 2%;
}
.inset-top-small {
  padding-top: 1%;
}
.inset-top-medium {
  padding-top: 3%;
}
.inset-top-large {
  padding-top: 6%;
}

.inset-bottom {
  padding-bottom: 2%;
}
.inset-bottom-small {
  padding-bottom: 1%;
}
.inset-bottom-medium {
  padding-bottom: 3%;
}
.inset-bottom-large {
  padding-bottom: 6%;
}

/*---------------------------*/
/*- CLEAR PADDING -----------*/
.no-inset {
  padding: 0;
}
.no-inset-top {
  padding-top: 0;
}
.no-inset-bottom {
  padding-bottom: 0;
}

/*---------------------------*/
/*- FLOATS AND CLEARS -------*/
.pull-left {
  float: left;
}
.column .pull-right,
.column.pull-right {
  order: 2;
}
.pull-right {
  float: right;
}
.clearit {
  clear: both;
}

.image-left {
}
.image-right {
}
.image-top {
}

/*-------------------------------*/
/*- ALIGNMENT & SPACING ---------*/

/*---------------------------*/
/*- VERTICAL MARGIN ---------*/
.push-top {
  margin-top: 2%;
}
.push-top-small {
  margin-top: 1%;
}
.push-top-medium {
  margin-top: 3%;
}
.push-top-large {
  margin-top: 6%;
}
.push-bottom {
  margin-bottom: 2%;
}
.push-bottom-small {
  margin-bottom: 1%;
}
.push-bottom-medium {
  margin-bottom: 3%;
}
.push-bottom-large {
  margin-bottom: 6%;
}

/*---------------------------*/
/*- HORIZONTAL MARGIN ---------*/
.push-left {
  margin-left: 2%;
}
.push-left-small {
  margin-left: 1%;
}
.push-left-medium {
  margin-left: 3%;
}
.push-left-large {
  margin-left: 6%;
}
.push-right {
  margin-right: 2%;
}
.push-right-small {
  margin-right: 1%;
}
.push-right-medium {
  margin-right: 3%;
}
.push-right-large {
  margin-right: 6%;
}

/*---------------------------*/
/*- CLEAR MARGIN ------------*/
.no-push {
  margin: 0;
}
.no-push-bottom {
  margin-bottom: 0;
}
.no-push-right {
  margin-right: 0;
}

/*-------------------------------*/
/*- DEFINE ----------------------*/

.define {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.define-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.define-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/*-------------------------------*/
/*- HEADERS ---------------------*/

.header-site {
  background-color: #f3f3f3;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.header-site .container,
.header-site > .header-block {
  display: -webkit-flex;
  -webkit-flex-wrap: nowrap;
  -webkit-align-items: center;
  -webkit-justify-content: space-between;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  position: relative;
}
.header-site .container {
  padding: 10px;
}

/*---------------------------*/
/*- NAV DISPLAY -------------*/
.nav-horizontal ul {
  display: none;
}

/*---------------------------*/
/*- RESPONSIVE BUTTON -------*/
.responsive-nav {
  padding: 0;
  margin: 0 0 0 20px;
}
.responsive-nav .l-menu {
  display: block;
  font-size: 24px;
  line-height: 40px;
  color: #454545;
}

/*---------------------------*/
/*- DEFAULT ALIGNMENT -------*/
.header-site nav {
  display: -webkit-flex;
  -webkit-flex-direction: row;
  -webkit-align-items: center;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0;
  line-height: 30px;
}
.header-left .logo {
  overflow: hidden;
}
.header-left .sitename {
  margin: 0 10px 0 0;
}

/*---------------------------*/
/*- ALIGNMENT RIGHT ---------*/
.header-right .container,
.header-right > .header-block {
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.header-right .logo {
  overflow: hidden;
}
.header-right .sitename {
  margin: 0 10px 0 0;
}

/*---------------------------*/
/*- POSITION OVERLAY --------*/
.header-sticky {
  width: 100%;
  z-index: 100;
  margin-top: 0;
  transition: background 0.1s ease-in-out, margin-top 0.15s ease-in-out,
    opacity 0.15s ease-in-out;
}
.header-sticky.is_stuck {
  opacity: 0;
}
.header-sticky.is_stuck.is_stuck_show {
  opacity: 1;
}
.header-site.header-sticky {
  background-color: rgba(243, 243, 243, 0.5);
}
.header-overlay {
  position: absolute;
  width: 100%;
  z-index: 100;
}
.header-overlay-no-push .header-overlay:not(.is_stuck) {
  background: transparent;
}

/*---------------------------*/
/*- SECONDARY HEADER --------*/
.header-secondary {
  display: none;
  padding: 10px 0;
  background: #333;
}
.header-secondary nav {
  margin: 0;
  font-size: 1.2rem;
}
.header-secondary .sub-menu {
  display: none;
}

/*-------------------------------*/
/*- FOOTER ----------------------*/

.footer-site {
  clear: both;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f3f3f3;
}
.footer-site .row {
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer-site .section-nav-title {
  margin-bottom: 15px;
  color: #323232;
}

.link-list li {
  margin: 0 0 6px;
}

.homepage .footer-site {
  padding-top: 0px;

  .container {
    padding-top: 0px
  }
}

/*---------------------------*/
/*- FOOTER COPY -------------*/
.copyright {
  color: rgba(0, 0, 0, 0.45);
  font-size: 1.2rem;
}
.grid.copyright {
  padding-top: 0;
  padding-bottom: 0;
}
.copyright .column {
  margin-bottom: 0;
}
.copyright .nav-horizontal li {
  margin: 0 0 0 20px;
}
.copyright .nav-horizontal a {
  padding: 0;
  font-size: 1.2rem;
  font-weight: 700;
}
.copyright .nav-horizontal a:hover {
  background: none;
}
.grid + .copyright {
  padding-top: 20px;
  border-top: 2px solid rgba(0, 0, 0, 0.15);
}
.invert .copyright .nav-horizontal a {
  color: rgba(255, 255, 255, 0.75);
}
.invert .copyright .nav-horizontal a:hover {
  color: rgba(255, 255, 255, 1);
}

/*---------------------------*/
/*- LAYERS BADGE ------------*/
.created-using-layers {
  display: none;
}

/*-------------------------------*/
/*- TITLES ----------------------*/

/*---------------------------*/
/*- PAGE TITLES -------------*/
.title-container {
  background-color: #f3f3f3;
}
.title-container .excerpt {
  max-width: 745px;
  margin-top: 1.5%;
}
.title-container .heading {
  color: #323232;
  font-size: 4.5rem;
  line-height: 5rem;
}
.title-container .excerpt {
  color: #8f8f8f;
  font-size: 2.5rem;
}
.title-container .excerpt + .button-group {
  margin-top: 3%;
}
.title-container .excerpt + .btn-collection {
  margin-top: 3%;
}
.title .text-center .excerpt {
  margin-left: auto;
  margin-right: auto;
}
.title-container .title {
  padding: 3% 20px;
  max-width: 1040px;
  margin: auto;
}

/*---------------------------*/
/*- TITLE SIZES -------------*/
.title.small {
  padding: 1%;
}
.title.large {
  padding: 3%;
}
.title.extra-large {
  padding: 4%;
}
.title.massive {
  padding: 6%;
}

/*---------------------------*/
/*- SECTION TITLES ----------*/
.section-title {
  margin-bottom: 30px;
}
.section-title .excerpt {
  max-width: 745px;
}
.section-title .heading + .excerpt {
  margin-top: 10px;
}
.section-title .button {
  margin-top: 15px;
}

/*-----------------------*/
/*- SECTION SIZE --------*/
.section-title.large .excerpt {
  max-width: 830px;
}
.section-title.small {
  margin-bottom: 15px;
}
.section-title.tiny {
  margin-bottom: 15px;
}
.section-title.tiny .excerpt {
  margin-top: 0 !important;
}

/*-----------------------*/
/*- SECTION LARGE -------*/
.section-title.large .heading {
  font-size: 4rem;
  line-height: 1.25;
}
.section-title.large .excerpt {
  font-size: 2.5rem;
}
.section-title.large .excerpt p:not(:first-child) {
  margin-top: 1.5rem;
}
.section-title.large .excerpt ul,
.section-title.large .excerpt ol {
  margin: 0 0 0 5rem;
}
.section-title.large .excerpt ul:not(:first-child),
.section-title.large .excerpt ol:not(:first-child) {
  margin-top: 1.5rem;
}

/*-----------------------*/
/*- SECTION SMALL -------*/
.section-title.small .heading {
  font-size: 2.5rem;
}
.section-title.small .excerpt {
  font-size: 1.5rem;
}
.section-title.small .excerpt p:not(:first-child) {
  margin-top: 0.5rem;
}
.section-title.small .excerpt ul,
.section-title.small .excerpt ol {
  margin: 0 0 0 3rem;
}
.section-title.small .excerpt ul:not(:first-child),
.section-title.small .excerpt ol:not(:first-child) {
  margin-top: 0.5rem;
}

/*-----------------------*/
/*- SECTION REGULAR -----*/
.section-title .heading {
  color: rgba(0, 0, 0, 0.85);
  font-size: 3rem;
}
.section-title .heading a {
  color: rgba(0, 0, 0, 0.85);
}
.section-title .heading a:hover {
  color: rgba(0, 0, 0, 1);
}
.section-title .excerpt {
  color: rgba(0, 0, 0, 0.55);
  font-size: 2rem;
}
.section-title .excerpt p:not(:first-child) {
  margin-top: 1rem;
}
.section-title .excerpt ul,
.section-title .excerpt ol {
  margin: 0 0 0 4rem;
}
.section-title .excerpt ul:not(:first-child),
.section-title .excerpt ol:not(:first-child) {
  margin-top: 1rem;
}

/*-----------------------*/
/*- SECTION TINY --------*/
.section-title.tiny .heading {
  font-size: 1.5rem;
  font-weight: 700;
}
.section-title.tiny .excerpt {
  font-size: 1.3rem;
}
.section-title.tiny .excerpt p:not(:first-child) {
  margin-top: 0.5rem;
}
.section-title.tiny .excerpt ul,
.section-title.tiny .excerpt ol {
  margin: 0 0 0 3rem;
}
.section-title.tiny .excerpt ul:not(:first-child),
.section-title.tiny .excerpt ol:not(:first-child) {
  margin-top: 1.5rem;
}

.section-title + .container {
  margin-top: 60px;
}
.section-title.text-right .excerpt {
  float: right;
}

/*---------------------------*/
/*- SECTION ALIGNMENTS ------*/
.section-title.text-center .excerpt {
  margin-left: auto;
  margin-right: auto;
}

/*---------------------------*/
/*- SECTION NAV -------------*/
.section-nav-title {
  margin: 0 0 10px 0;
  color: #8f8f8f;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
}

/*-------------------------------*/
/*- SIDEBAR ---------------------*/

.sidebar {
  overflow: hidden;
}

/*-------------------------------*/
/*- OFF CANVAS ------------------*/

/*---------------------------*/
/*- CLOSE CANVAS BUTTON -----*/
.close-canvas {
  padding: 4px 8px 3px;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 0 0 3px;
  font-size: 1.1rem;
  text-transform: uppercase;
  text-align: right;
  cursor: pointer;
  z-index: 2;
}
.close-canvas i {
  margin-right: 4px;
  font-size: 1rem;
}
.close-canvas:hover {
  color: #fff;
}
.admin-bar div[class*=" off-canvas-"] .close-canvas {
  top: 32px;
}

div[class*=" off-canvas-"] {
  padding-top: 30px;
  position: fixed;
  top: 0;
  z-index: 1;
  opacity: 0;
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
  transition: all 0.3s ease-in-out 0s;
  font-size: 1.4rem;
}
.admin-bar div[class*=" off-canvas-"] {
  padding-top: 66px;
}
.off-canvas-right {
  width: 240px;
  height: 100%;
  right: 0;
}
.off-canvas-left {
  width: 240px;
  height: 100%;
}
.off-canvas-top {
  width: 100%;
  height: 200px;
}

.open[class*=" off-canvas-"] {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  overflow-y: scroll;
}

/*---------------------------*/
/*- WRAPPER POSITIONING -----*/
.open + .wrapper-site {
  opacity: 0.85;
}
.off-canvas-right.open + .wrapper-site {
  left: -240px;
}
.off-canvas-left.open + .wrapper-site {
  left: 240px;
}
.off-canvas-top.open + .wrapper-site {
  top: 200px;
}

/*---------------------------*/
/*- OFF CANVAS WIDGETS ------*/
div[class*=" off-canvas-"] .sub-menu li {
  width: auto;
}
div[class*=" off-canvas-"] li {
  padding: 5px 0;
}
div[class*=" off-canvas-"] li a {
  color: rgba(255, 255, 255, 0.8);
}
div[class*=" off-canvas-"] .section-nav-title {
  color: #888;
}
div[class*=" off-canvas-"] .widget.widget_nav_menu {
  padding: 20px;
}
div[class*=" off-canvas-"] .widget.widget_nav_menu li {
  display: block;
  margin: 0;
  text-align: left;
}
div[class*=" off-canvas-"] .searchform label {
  display: none;
}
div[class*=" off-canvas-"] .searchform input[type="text"] {
  background: none repeat scroll 0% 0% rgb(51, 51, 51);
  border: 2px solid rgb(85, 85, 85);
}
div[class*=" off-canvas-"] .searchform input[type="submit"] {
  display: block;
  width: 100%;
  height: 32px;
  background: #555;
  color: #ccc;
  font-size: 1.2rem;
}

/*-----------------------*/
/*- OFF CANVAS MENU -----*/
div[class*=" off-canvas-"] .nav-mobile {
  margin-top: 0;
  padding-top: 0;
  position: relative;
  z-index: 1;
}
.admin-bar div[class*=" off-canvas-"] .nav-mobile {
  padding-top: 20px;
}
div[class*=" off-canvas-"] .nav-vertical li {
  padding: 0;
}
div[class*=" off-canvas-"] .nav-vertical .sub-menu {
  display: block;
  width: auto;
  visibility: visible;
  opacity: 1;
  position: static;
  border: none;
  box-shadow: none;
  font-size: 1.2rem;
}
div[class*=" off-canvas-"] .nav-vertical .sub-menu li {
  border: none;
}

/*-------------------------------*/
/*- FORMS -----------------------*/
/*-------------------------------*/

/*---------------------------*/
/*- DEFAULTS ----------------*/
textarea,
input,
select,
button {
  width: 100%;
  background-color: #fff;
  border: 2px solid #eee;
  color: #8f8f8f;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
}
textarea:focus,
input:focus,
select:focus {
  border-color: #ccc;
  -webkit-appearance: none;
  outline: 0;
}
input[type="email"],
input[type="text"],
input[type="url"],
input[type="search"],
input[type="password"],
input[type="number"],
input[type="tel"],
input[type="date"] {
  max-width: 500px;
  padding: 0 8px;
  height: 36px;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 1.5rem;
}
textarea {
  padding: 10px 8px;
  max-width: 660px;
  -webkit-appearance: none;
  -moz-appearance: none;
}
select {
  max-width: 500px;
  padding: 0 6px;
  height: 36px;
}

/*---------------------------*/
/*- FORM BUTTONS ------------*/
input[type="button"],
input[type="submit"],
button {
  display: inline-block;
  width: auto;
  padding: 5px 10px;
  vertical-align: middle;
  background-color: #454545;
  border: none;
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: all 0.2s ease-in-out;
}
input[type="button"]:hover,
input[type="submit"]:hover,
button:hover {
  cursor: pointer;
  background-color: #ccc;
  color: #999;
}
input[type="button"]:disabled,
input[type="submit"]:disabled {
  background-color: #ccc;
  color: #999;
}
input[type="submit"] + span {
  float: left;
  margin: 2px 0 0 10px;
  font-size: 1.1rem;
  font-style: italic;
}

/*---------------------------*/
/*- CHECKBOXES AND RADIOS ---*/
input[type="checkbox"] {
  border: none;
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
}
input[type="radio"] {
  border: none;
  -webkit-appearance: radio;
  -moz-appearance: radio;
}

/*---------------------------*/
/*- UPLOAD FIELD ------------*/
input[type="upload"],
input[type="file"] {
  max-width: 500px;
  padding: 4px;
  background-color: #eee;
  border: none;
  font-size: 1.5rem;
}

/*---------------------------*/
/*- LABELLING AND GROUPING --*/
label {
//  clear: both;
//  display: block;
  margin-bottom: 4px;
  font-size: 1.5rem;
  font-weight: 700;
}
fieldset {
  margin-bottom: 10px;
}
legend {
  margin: 0 0 10px;
  font-size: 1.9rem;
}

/*---------------------------*/
/*- NOTES -------------------*/
form small {
  display: block;
  margin-top: 5px;
}
form label + small {
  max-width: 500px;
  margin: 0 0 5px;
}

/*---------------------------*/
/*- FORM ROWS ---------------*/
.form-row {
  margin-bottom: 15px;
  vertical-align: top;
}
.form-row:last-child {
  margin-bottom: 0;
}

/*---------------------------*/
/*- FORM ROWS LARGE ---------*/
form.large label {
  font-size: 1.5rem;
}
form.large input[type="email"],
form.large input[type="text"],
form.large input[type="password"] {
  height: 47px;
  font-size: 2rem;
}
form.large .input-append .add-on,
form.large .input-prepend .add-on {
  line-height: 43px;
}
form.large .input-append input[type="submit"],
form.large .input-append input[type="button"],
form.large .input-append button {
  height: 47px;
}

/*---------------------------*/
/*- FORM ROWS SMALL ---------*/
form.small .form-row {
  margin-bottom: 5px;
}

form.small input[type="email"],
form.small input[type="text"],
form.small input[type="password"] {
  height: 32px;
  font-size: 1.3rem;
}
form.small .input-append .add-on,
form.small .input-prepend .add-on {
  line-height: 28px;
}
form.small .input-append input[type="submit"],
form.small .input-append input[type="button"],
form.small .input-append button {
  height: 32px;
}

/*---------------------------*/
/*- CHECKBOX ----------------*/
input[type="checkbox"] {
  width: auto;
  height: 14px;
  width: 14px;
  padding: 0;
  margin: 0 5px 0 5px;
  vertical-align: middle;
  font-size: 1.2rem;
  font-weight: 400;
}
.checkbox {
  clear: both;
  margin: 0;
  overflow: hidden;
}
.checkbox input[type="submit"],
.checkbox button {
  display: inline-block;
  margin: 0;
}
.checkbox label {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}
.checkbox .btn-large + label {
  padding-top: 10px;
  font-size: 1.3rem;
}

/*---------------------------*/
/*- CHECKBOX LIST -----------*/
.checkbox-list .checkbox {
  margin-bottom: 5px;
}
.checkbox-list .checkbox label {
  margin-left: 0;
  font-size: 1.3rem;
}

/*---------------------------*/
/*- RADIO BUTTONS -----------*/
input[type="radio"] {
  width: auto;
  height: 14px;
  width: 14px;
  padding: 0;
  margin: 0 5px 0 5px;
  vertical-align: bottom;
}
.radio-button-group {
  display: block;
  overflow: hidden;
}
.radio-button-group label {
  overflow: hidden;
  clear: none;
  float: none;
  padding: 8px 0;
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 400;
}

/*---------------------------*/
/*- PREPENDS + APPENDS ------*/
.input-prepend,
.input-append {
  overflow: hidden;
  display: block;
  line-height: 32px;
  white-space: nowrap;
  font-size: 0;
}
.input-prepend .add-on {
  display: inline-block;
  padding: 0 12px;
  background-color: #eee;
  border: 2px solid #eee;
  border-right-width: 0px;
  font-size: 1.5rem;
}
.input-prepend input {
  display: inline-block;
  vertical-align: top;
}

.input-append .add-on {
  display: inline-block;
  padding: 0 12px;
  background-color: #eee;
  border: 2px solid #eee;
  border-left-width: 0px;
  font-size: 1.5rem;
}
.input-append input[type="text"],
.input-append input[type="password"],
.input-append input[type="email"] {
  display: inline-block;
  vertical-align: top;
  border-right: none;
}
.input-append input[type="submit"],
.input-append input[type="button"],
.input-append button {
  display: inline-block;
  height: 36px;
  margin: 0;
  vertical-align: top;
  border-left: none;
}
.input-prepend.input-append input {
  border-right-width: 0;
  border-right-width: 0;
}
.input-prepend.input-append .add-on:first-child {
  border-left-width: 2px;
  border-right-width: 0;
}

/*-------------------------------*/
/*- BUTTONS ---------------------*/
/*-------------------------------*/

/*---------------------------*/
/*- DEFAULT BUTTON ----------*/
.button {
  display: inline-block;
  padding: 5px 15px;
  background-color: #454545;
  border-width: 0;
  border-color: #454545;
  border-style: solid;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 700;
}
.button:hover {
  background-color: #323232;
  color: #fff;
}

/*---------------------------*/
/*- BUTTON SIZES ------------*/
.button.btn-small {
  padding: 2px 10px;
  font-size: 1.2rem;
}
.button.btn-medium {
  padding: 5px 15px;
  font-size: 1.5rem;
}
.button.btn-large {
  padding: 10px 25px;
  font-size: 1.8rem;
}
.button.btn-massive {
  padding: 15px 30px;
  font-size: 2rem;
}
.button.btn-full {
  display: block;
  width: 100%;
}

/*---------------------------*/
/*- BUTTON COLORS -----------*/

/*-----------------------*/
/*- FORM BUTTON ---------*/
input.button {
  height: 36px;
  padding-top: 0;
  padding-bottom: 0;
}

/*-----------------------*/
/*- PRIMARY BUTTON ------*/
.btn-primary {
  background-color: #fc0;
  color: #5e2500;
}
.btn-primary:hover {
  background-color: #f93;
  color: #5e2500;
}

/*-----------------------*/
/*- SECONDARY BUTTON ----*/
.btn-secondary {
  background-color: #a3c567;
  color: #37451d;
}
.btn-secondary:hover {
  background-color: #93b25e;
  color: #37451d;
}

/*-----------------------*/
/*- SUBTLE BUTTON -------*/
.btn-subtle {
  background-color: #eee;
  color: #454545;
}
.btn-subtle:hover {
  background-color: #eee;
  color: #323232;
}

/*-----------------------*/
/*- LINK BUTTON ---------*/
.btn-link {
  background: none;
  text-decoration: underline;
  color: #454545;
}
.btn-link:hover {
  background: none;
  color: #323232;
}

/*-----------------------*/
/*- LINK BUTTON ---------*/
.button.btn-invert {
  background-color: #fff;
  color: #454545;
}
.button.btn-invert:hover {
  background-color: #454545;
  color: #fff;
}

/*---------------------------*/
/*- BUTTON GROUP ------------*/
.button-group {
  display: inline-block;
  margin-bottom: 10px;
  vertical-align: middle;
  overflow: hidden;
}
.button-group + .button-group {
  margin-right: 10px;
}
.pull-right .button-group + .button-group {
  margin-left: 10px;
  margin-right: 0;
}
.button-group .button {
  float: left;
  margin: 0;
}

/*---------------------------*/
/*- BUTTON COLLECTION -------*/
.button-collection {
  margin-bottom: 20px;
  vertical-align: middle;
  overflow: hidden;
}

/*-------------------------------*/
/*- BADGES AND LABELS -----------*/
/*-------------------------------*/

/*---------------------------*/
/*- BADGE -------------------*/
.badge {
  padding: 1px 8px;
  background-color: #000;
  color: #fff;
}
.button .badge {
  margin: 0 2px 0 -10px;
}

/*-----------------------*/
/*- BADGE COLORS --------*/

/*-------------------*/
/*- PRIMARY BADGE ---*/
.badge.badge-primary {
  background-color: #5e2500;
  color: #fff;
}

/*-------------------*/
/*- SECONDARY BADGE -*/
.badge.badge-secondary {
  background-color: #37451d;
  color: #fff;
}

/*------------------*/
/*- SUBTLE BADGE ---*/
.badge.badge-subtle {
  background-color: #8f8f8f;
  color: #fff;
}

/*------------------*/
/*- BLANK BADGE ----*/
.badge.badge-blank {
  background-color: #ddd;
  color: #323232;
}

/*---------------------------*/
/*- LABEL -------------------*/
.label {
  padding: 1px 6px;
  background-color: #454545;
  color: #fff;
  white-space: nowrap;
  font-size: 1.2rem;
  font-weight: 700;
  font-style: normal;
}

/*-----------------------*/
/*- LABEL COLORS --------*/

/*-------------------*/
/*- PRIMARY LABEL ---*/
.label.label-primary {
  background-color: #5e2500;
  color: #fff;
}

/*-------------------*/
/*- SECONDARY LABEL -*/
.label.label-secondary {
  background-color: #a3c567;
  color: #fff;
}

/*-------------------*/
/*- SUBTLE LABEL ----*/
.label.label-subtle {
  background-color: #c0c0c0;
  color: #fff;
}

/*-------------------*/
/*- ERROR LABEL -----*/
.label.label-error {
  background-color: #e2574c;
  color: #fff;
}

/*-------------------*/
/*- BLANK LABEL -----*/
.label.label-blank {
  background-color: #ddd;
  color: #323232;
}

/*-------------------*/
/*- LABEL SIMPLE ----*/
.label.label-nofrill {
  background: none;
  color: #999;
}

/*-------------------*/
/*- LABEL SIMPLE ----*/
.label.label-nofrill {
  display: block;
  padding: 0;
}

/*---------------------------*/
/*- LABEL GROUP -------------*/
.label-group {
  overflow: hidden;
  font-size: 0;
}
.label-group .label {
  margin: 0;
}

/*-------------------------------*/
/*- TABLES ----------------------*/
/*-------------------------------*/

table {
  width: 100%;
  margin-bottom: 20px;
  vertical-align: top;
  overflow: hidden;
  border: 2px solid rgba(0, 0, 0, 0.08);
}
table th {
  padding: 10px;
  text-align: left;
  vertical-align: middle;
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
  color: #454545;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
}
table td {
  padding: 10px;
  vertical-align: top;
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
}
table tr:last-child td {
  border-bottom: none;
}
table .button-group {
  margin-bottom: 0;
}

.section-table-title td {
  padding-top: 2px;
  padding-bottom: 0;
  background-color: #f3f3f3;
  color: #8f8f8f;
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
}

table.table-comfortable td {
  padding: 14px 10px;
}

/*-------------------------------*/
/*- WRAPPERS --------------------*/
/*-------------------------------*/

/*---------------------------*/
/*- WRAPPERS ----------------*/
#wrapper-site,
.wrapper-site {
  background-color: #fff;
}

/*---------------------------*/
/*- WELLS -------------------*/
.well {
  background-color: rgba(0, 0, 0, 0.05);
}
.well-block {
  background-color: #fff;
  border: 3px solid rgba(0, 0, 0, 0.08);
}

/*---------------------------*/
/*- PANEL -------------------*/
.panel {
  overflow: hidden;
  margin-bottom: 20px;
}
.panel ul {
  margin: 0;
  padding: 0;
  border: 2px solid rgba(0, 0, 0, 0.05);
}
.panel li {
  padding: 10px;
  background-color: #fff;
  border-top: 2px solid rgba(0, 0, 0, 0.05);
}
.panel li:first-child {
  border-top: none;
}
.panel .panel-title {
  padding: 8px 10px;
  background-color: #454545;
  color: #fff;
}
.panel .panel-title + ul {
  border-top: none;
}
.panel li .heading {
  margin-bottom: 5px;
  color: #999;
}

/*-------------------------------*/
/*- HEADER ----------------------*/
/*-------------------------------*/

/*---------------------------*/
/*- SITE WIDE HEADER --------*/
.header-site .column {
  margin-bottom: 0;
}

/*---------------------------*/
/*- LOGO --------------------*/
.logo {
  padding: 0;
}
.sitename {
  font-size: 1.8rem;
  font-weight: 700;
}
.site-logo-link + .site-description .sitename {
  font-size: 1.6rem;
}
.tagline {
  font-size: 1.2rem;
  font-weight: 700;
}
.site-logo-link,
.logo .custom-logo-link,
.mark {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  font-size: 0;
}
.site-logo-link img,
.custom-logo-link img,
.mark img {
  display: inline-block;
  max-height: 40px;
  margin: auto;
  width: auto;
}
.site-description {
  display: inline-block;
  vertical-align: middle;
}
.site-logo-link + .site-description,
.custom-logo-link + .site-description {
  margin-left: 10px;
}
.mark + .sitename {
  margin-top: 10px;
}
.column .logo {
  float: none;
}

/*-------------------------------*/
/*- NAVIGATION ------------------*/
/*-------------------------------*/

.nav {
  vertical-align: middle;
  display: inline-block;
}
.nav li {
  position: relative;
}
.nav-small a,
.nav-small span {
  font-size: 1.2rem;
}
.nav a {
  color: #323232;
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
}
.nav a:hover {
  color: #8f8f8f;
}
.nav.large {
  font-size: 2rem;
}
.nav.medium {
  font-size: 1.5rem;
}

/*---------------------------*/
/*- HORIZONTAL NAV ----------*/
.nav-horizontal > ul {
  float: left;
}
.nav-horizontal li {
  float: left;
  margin: 0 10px;
}
.nav-horizontal li a {
  display: block;
  color: #323232;
  text-align: center;
  font-weight: 700;
}
.nav-horizontal li a:hover {
  color: #8f8f8f;
}
.nav-horizontal .special {
  padding: 2px 0;
  font-size: 1.2rem;
}

/*-----------------------*/
/*- SITE HEADER > NAV ---*/
.header-site .nav-horizontal > ul > li {
  margin: 0 2px;
}
.header-site .nav-horizontal > ul > li > a {
  padding: 0 10px;
}

/*---------------------------*/
/*- HORIZONTAL NAV CENTERED -*/
.nav-centered {
  text-align: center;
  width: 100%;
}
.nav-centered li {
  float: none;
  display: inline-block;
  margin: 0;
}

/*---------------------------*/
/*- VERTICAL NAV ------------*/
.nav-vertical {
  clear: both;
}
.nav-vertical a {
  display: block;
  padding: 5px 0;
  font-weight: 700;
}
.nav-vertical a:hover {
  background-color: #eee;
  color: #323232;
}

/*---------------------------*/
/*- INLINE NAV --------------*/
.nav-inline li {
  display: inline;
  margin: 0 15px;
  font-size: 1.2rem;
}
.nav-inline a {
  color: rgba(255, 255, 255, 0.65);
}
.nav-inline a:hover {
  color: #fff;
}

/*---------------------------*/
/*- TABBED NAV --------------*/
.nav-tabs {
  padding: 20px 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.nav-tabs ul {
  overflow: hidden;
  max-width: 1000px;
  margin: auto;
}
.nav-tabs li {
  float: left;
  margin-right: 10px;
}
.nav-tabs a {
  display: block;
  padding: 6px 15px;
  border: 1px solid transparent;
  border-bottom: none;
  font-weight: 700;
}
.nav-tabs a:hover {
  background: rgba(0, 0, 0, 0.1);
}
.nav-tabs .active a {
  border-color: rgba(0, 0, 0, 0.1);
}
.nav-small a,
.nav-small span {
  padding: 3px 8px;
}

/*---------------------------*/
/*- PILL NAV ----------------*/
.nav-pills {
}
.nav-pills li {
  display: inline-block;
}
.nav-pills a {
  border: 2px solid #ddd;
  color: #8f8f8f;
  font-weight: 700;
  padding: 2px 10px;
  border-radius: 3px;
  display: block;
}
.nav-pills a:hover {
  background-color: #8f8f8f;
  border-color: #454545;
  color: #fff;
}
.nav-pills .active a {
  background-color: #8f8f8f;
  border-color: #454545;
  color: #fff;
}
.nav-pills span {
  color: #323232;
  font-weight: 700;
}

/*---------------------------*/
/*- SUB NAV -----------------*/
.sub-menu,
.nav .children {
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 50px;
  left: 0;
  border: 2px solid #ddd;
  z-index: 5;
  transition: all 0.4s ease-in-out;
  line-height: normal;
}
.sub-menu li,
.nav .children li {
  clear: both;
  display: block;
  width: 180px;
  margin: 0;
  border: none;
  border-bottom: 1px solid #ddd;
}
.sub-menu li:last-child,
.nav .children li:last-child {
  border-bottom: none;
}
.sub-menu li a,
.nav .children li a {
  display: block;
  padding: 8px 10px;
  background: none;
  color: #454545;
  text-transform: none;
  text-align: left;
}
.sub-menu li a:hover,
.nav .children li a:hover {
  background-color: #333;
  color: #fff;
}
.nav li:hover > .sub-menu,
.nav li:hover .children {
  visibility: visible;
  opacity: 1;
  top: 40px;
  transition: all 0.2s ease-in-out;
}
.nav > ul > li .sub-menu .sub-menu,
.nav li:hover > .sub-menu .sub-menu,
.nav > ul > li .children .children,
.nav li:hover > .children .children {
  left: 185px;
  top: -3px;
}
.nav > ul > li:last-child .sub-menu,
.nav > ul > li:last-child .children {
  right: 0;
  left: auto;
}
.nav li:last-child .sub-menu .sub-menu,
.nav li:last-child .children .children {
  right: 185px;
  left: auto;
}
.nav-vertical .sub-menu,
.nav-vertical .children {
  left: 180px;
  right: auto;
  top: -3px;
  box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.05);
}
.nav-vertical li:hover > .sub-menu,
.nav-vertical li:hover > .children {
  top: -3px;
  left: 140px;
}
.nav-vertical li:hover > .sub-menu .sub-menu,
.nav-vertical li:hover > .chilren .children {
  left: 155px;
  right: auto;
  top: -3px;
}
#footer .sub-menu {
  display: none;
}

/*-----------------------*/
/*- ARROWS --------------*/
.header-site .menu-item-has-children > a:after {
  margin-left: 8px;
  content: "\e605";
  font-family: "layers-icons";
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  opacity: 0.4;
  font-weight: normal;
  font-size: 0.8rem;
  vertical-align: middle;
}
.header-site .sub-menu .menu-item-has-children > a:after {
  float: right;
  padding: 5px 0 0;
  content: "\e600";
}
.header-site .menu-item-has-children > a:hover:after {
  opacity: 0.8;
}

/*-----------------------*/
/*- SUB MENU ANIMATION --*/
.header-site .nav ul li > .sub-menu > li {
  opacity: 0;
  transform: translate3d(0, 5px, 0);
  transition: 0.25s opacity ease-in-out 0.05s, 0.25s transform ease-in-out 0.05s;
}
.header-site .nav ul li:hover > .sub-menu > li {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.header-site .nav ul li:hover > .sub-menu > li:nth-child(2) {
  transition-delay: 0.07s;
}
.header-site .nav ul li:hover > .sub-menu > li:nth-child(3) {
  transition-delay: 0.09s;
}
.header-site .nav ul li:hover > .sub-menu > li:nth-child(4) {
  transition-delay: 0.11s;
}
.header-site .nav ul li:hover > .sub-menu > li:nth-child(5) {
  transition-delay: 0.13s;
}
.header-site .nav ul li:hover > .sub-menu > li:nth-child(6) {
  transition-delay: 0.15s;
}
.header-site .nav ul li:hover > .sub-menu > li:nth-child(7) {
  transition-delay: 0.17s;
}

/*---------------------------*/
/*- ABOLUTE NAV -------------*/
.nav-absolute {
  position: absolute;
  width: 100%;
  padding: 5px 10px;
  color: #999;
  font-size: 1.2rem;
}
.nav-absolute li {
  display: inline;
}
.nav-absolute a {
  color: #ccc;
}
.nav-absolute a:hover {
  color: #fff;
}

/*---------------------------*/
/*- STICKY NAV --------------*/
.sticky-nav {
  width: 100%;
  padding: 8px 0;
  position: fixed;
  top: -50px;
  overflow: hidden;
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.1);
}
.sticky-nav.sticky-nav-on {
  opacity: 1;
  visibility: visible;
  top: 0;
}
.sticky-nav h4 {
  padding: 2px 0;
  font-size: 2rem;
}
.sticky-nav h4 small {
  margin-left: 10px;
  text-transform: uppercase;
}

/*---------------------------*/
/*- BREAD CRUMBS ------------*/
.bread-crumbs {
  display: inline-block;
  margin: 0 0 15px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.55);
  font-size: 1.2rem;
}
.bread-crumbs li {
  float: left;
  margin-right: 10px;
}
.bread-crumbs a {
  color: rgba(0, 0, 0, 0.75);
}
.bread-crumbs a:hover {
  color: rgba(0, 0, 0, 0.85);
  text-decoration: underline;
}
.title-small .bread-crumbs {
  margin: -30px 0 25px;
}

/*---------------------------*/
/*- PAGINATION --------------*/
.pagination {
  clear: both;
  display: inline-block;
  overflow: hidden;
  margin: 0 20px 20px 0;
}
.pagination li {
  float: left;
  border-right: 1px solid #eee;
  overflow: hidden;
  font-size: 1.3rem;
  font-weight: 700;
}
.pagination li:last-child {
  border-right: none;
}
.pagination a,
.pagination span {
  display: inline-block;
  min-width: 38px;
  padding: 4px 10px;
  background-color: #f5f5f5;
  color: #454545;
  text-align: center;
}
.pagination a:hover {
  background-color: #454545;
  color: #fff;
}
.pagination .current {
  border-color: #323232;
}
.pagination .current {
  background-color: #454545;
  color: #fff;
}
.pagination li.next {
  margin-right: 10px;
  border: none;
}

/*-------------------------------*/
/*- COMPONENTS ------------------*/
/*-------------------------------*/

/*---------------------------*/
/*- BACK TO TOP -------------*/
#back-to-top {
  display: none;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 10;
}
#back-to-top a {
  display: block;
  width: 32px;
  height: 32px;
  background: "url('/images/arrow-up-small.png')" no-repeat;
  background-size: 32px 32px;
  text-indent: -999px;
  overflow: hidden;
}

/*---------------------------*/
/*- MEDIA BLOCK -------------*/
.media {
  overflow: hidden;
  display: block;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.media-image {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}
.media-image img {
  display: block;
  margin: auto;
}
.media-image.small img {
  max-width: 64px;
}
.image-rounded img,
.media-image.image-rounded img {
  border-radius: 100%;
}
.media .media-body {
  display: block;
  overflow: hidden;
}

.column:not(.span-12) .media .excerpt {
  max-width: 745px;
}

.media .text-center .excerpt {
  margin-left: auto;
  margin-right: auto;
}

/*-----------------------*/
/*- IMAGE TOP -----------*/
.media.image-top .media-body {
  float: none;
}
.has-image .media-body {
  margin-top: 15px;
}

/*-----------------------*/
/*- MEDIA SMALL ---------*/
.media.small .heading + .excerpt {
  margin-top: 5px;
}
.has-image .small .media-body {
  margin-top: 10px;
}
.media.small p:not(:first-child),
.media.small .button {
  margin-top: 1.3rem;
}
.media.small .heading {
  font-size: 1.5rem;
  font-weight: 700;
}
.media.small .excerpt {
  font-size: 1.3rem;
}
.media.small .excerpt ul,
.media.small .excerpt ol {
  margin: 0 0 0 3rem;
}
.media.small .excerpt ul:not(:first-child),
.media.small .excerpt ol:not(:first-child) {
  margin-top: 1.3rem;
}

/*-----------------------*/
/*- MEDIA MEDIUM --------*/
.media.medium .heading + .excerpt {
  margin-top: 10px;
}
.media .heading {
  font-size: 2rem;
}
.media .heading + .excerpt {
  font-size: 1.5rem;
}
.media.medium p:not(:first-child),
.media.medium .button {
  margin-top: 1.5rem;
}
.media.medium .excerpt ul,
.media.medium .excerpt ol {
  margin: 0 0 0 3rem;
}
.media.medium .excerpt ul:not(:first-child),
.media.medium .excerpt ol:not(:first-child) {
  margin-top: 1.5rem;
}

/*-----------------------*/
/*- MEDIA LARGE ---------*/
.media.large .heading + .excerpt {
  margin-top: 10px;
}
.media.large .text-center .excerpt {
  margin-left: auto;
  margin-right: auto;
}
.media.large p:not(:first-child),
.media.large .button {
  margin-top: 2rem;
}
.media.large .heading {
  font-size: 2.8rem;
}
.media.large .excerpt {
  font-size: 2rem;
}
.media.large .excerpt ul,
.media.large .excerpt ol {
  margin: 0 0 0 4rem;
}
.media.large .excerpt ul:not(:first-child),
.media.large .excerpt ol:not(:first-child) {
  margin-top: 2rem;
}

/*---------------------------*/
/*- THUMBNAIL BLOCK ---------*/
.thumbnail {
  display: block;
  position: relative;
  overflow: hidden;
}
.thumbnail.hide {
  display: none;
}
.thumbnail-title {
  padding: 0 20px 20px;
}
.thumbnail-title.small {
  padding: 0 10px 10px;
}
.thumbnail-media {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.thumbnail-media.image-rounded {
  border-radius: 100%;
}
.thumbnail-media img {
  display: block;
  margin: auto;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: 0.2s all ease-in-out, 4s transform ease-in-out;
}
.thumbnail-media:hover img {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}
.thumbnail-body {
  padding: 20px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  background-color: #f5f5f5;
}
.thumbnail-body .heading {
  margin: 0;
  color: #000;
  font-size: 2rem;
  font-weight: 400;
}
.thumbnail-body .heading.small {
  font-size: 1.2rem;
}
.thumbnail-body .excerpt {
  color: rgba(0, 0, 0, 0.55);
}
.thumbnail-body p {
  margin: 10px 0 0;
}
.thumbnail-body p + .feature-list {
  margin: 20px 0 0;
}
.thumbnail-body .button {
  margin-top: 20px;
}
.thumbnail-body.body-small {
  padding: 0 10px 10px;
}
.thumbnail .button-group {
  margin: 20px 0 0;
}
.thumbnail .btn-collection {
  margin: 20px 0 0;
}

/*-----------------------*/
/*- THUMBNAIL ALIGNMENT -*/
.thumbnail-body .article-title + .excerpt {
  margin-top: 10px;
}
.thumbnail-body .article-title + .meta-info {
  margin-top: 10px;
}

/*-------------------------------*/
/*- COPY STYLING ----------------*/
/*-------------------------------*/

.copy {
  font-size: 1.5rem;
}
.copy.small {
  font-size: 1.3rem;
}
.copy p {
  margin-bottom: 16px;
}
.copy a {
  border-bottom: 1px solid #ddd;
}
.copy a:hover {
  border-color: #999;
}
.copy ul {
  list-style: disc;
  margin: 0 0 26px;
}
.copy ul li {
  list-style: disc;
  margin: 0 0 6px 30px;
}
.copy ol {
  list-style: decimal;
  margin: 0 0 26px;
}
.copy ol li {
  list-style: decimal;
  margin: 0 0 6px 20px;
}
.copy ul ul,
.copy ul ol,
.copy ol ol,
.copy ol ul {
  margin-bottom: 0;
}

.copy dl {
  margin: 0 0 26px;
}
.copy dd {
  margin-left: 36px;
}

.copy img {
  display: block;
  max-width: 98%;
  height: auto;
}
.copy h1 {
  margin-bottom: 26px;
  font-size: 3.2rem;
  font-weight: 700;
}
.copy h2 {
  margin-bottom: 26px;
  font-size: 2.2rem;
  font-weight: 700;
}
.copy h3 {
  margin-bottom: 26px;
  font-size: 1.8rem;
  font-weight: 700;
}
.copy h4 {
  margin-bottom: 26px;
  font-size: 1.5rem;
  font-weight: 700;
}
.copy h5 {
  margin-bottom: 26px;
  font-size: 1.3rem;
  font-weight: 700;
}
.copy h6 {
  margin-bottom: 26px;
  font-size: 1.3rem;
}
.copy blockquote {
  padding-left: 26px;
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: 700;
}
.copy a.button {
  border-bottom: none;
}

/*-------------------------------*/
/*- EXCERPT LIST STYLING --------*/
/*-------------------------------*/

.excerpt li {
  list-style-type: inherit;
}
.excerpt li ul,
.excerpt li ol {
  margin: 0 1.5rem;
}
.excerpt ul,
.excerpt ol {
  margin: 0 0 0 3rem;
}
.text-center ul,
.text-right ul,
.text-center ol,
.text-right ol {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.text-center ul li,
.text-right ul li,
.text-center ol li,
.text-right ol li {
  list-style-position: inside;
}
.excerpt ul:not(:first-child),
.excerpt ol:not(:first-child) {
  margin-top: 1.5rem;
}
.excerpt ul {
  list-style-type: disc;
}
.excerpt ol {
  list-style-type: decimal;
}
.excerpt ol ol {
  list-style: upper-alpha;
}
.excerpt ol ol ol {
  list-style: lower-roman;
}
.excerpt ol ol ol ol {
  list-style: lower-alpha;
}

/*-------------------------------*/
/*- COLOR RESETS ----------------*/
/*-------------------------------*/

.no-fill {
  background: none !important;
}

/*-------------------------------*/
/*- TEXT ALIGNMENT --------------*/
/*-------------------------------*/

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}

/*-------------------------------*/
/*- INVERT ----------------------*/
/*-------------------------------*/

.invert {
  border: none;
  color: rgba(255, 255, 255, 0.85);
}
.invert h1,
.invert h2,
.invert h3,
.invert h4,
.invert h5,
.invert h6 {
  color: rgba(255, 255, 255, 0.95);
}
.invert code {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  padding: 2px 4px;
}
.invert label,
.invert legend {
  color: #fff;
}
.invert small {
  color: rgba(255, 255, 255, 0.7);
}
.invert a {
  color: #fff;
}
.invert a:hover {
  color: #fff;
}

.invert .copy a:not(.button),
.invert .story a:not(.button) {
  color: rgba(255, 255, 255, 0.7);
  border-bottom-color: rgba(255, 255, 255, 0.7);
}

.invert blockquote {
  background: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.55);
}

.invert input,
.invert textarea,
.invert select {
  border-color: transparent;
}
.invert .input-prepend .add-on,
.invert .input-append .add-on {
  border-color: transparent;
  background: #323232;
  color: #fff;
}

/*-----------------------*/
/*- SECTION TITLE -------*/

.invert .section-title .heading,
.invert.section-title .heading {
  color: #fff;
}
.invert .section-title .excerpt,
.invert.section-title .excerpt {
  color: rgba(255, 255, 255, 0.8);
}

.invert .section-title .heading a,
.invert.section-title .heading a {
  color: #fff;
}

/*-----------------------*/
/*- LOGO INVERT ---------*/
.invert .sitename {
  color: #fff;
}
.invert .tagline {
  color: rgba(255, 255, 255, 0.9);
}

/*-----------------------*/
/*- PAGE TITLES INVERT --*/
.invert .heading {
  color: #fff;
}
.invert .sub-header,
.invert .excerpt {
  color: rgba(255, 255, 255, 0.9);
}

/*-----------------------*/
/*- NAV INVERT ----------*/
.invert .nav > li > a {
  color: rgba(255, 255, 255, 0.8);
}
.invert .nav > li > a:hover {
  color: rgba(255, 255, 255, 1);
}
.invert .nav-tabs a {
  color: #fff;
}

/*---------------------------*/
/*- HEADER INVERT -----------*/
.header-site.invert .nav-horizontal > ul > li > a {
  color: rgba(255, 255, 255, 1);
}
.header-site.invert .nav-horizontal > ul > li > a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.header-site.invert .responsive-nav .l-menu {
  color: rgba(255, 255, 255, 1);
}

/*---------------------------*/
/*- BREAD CRUMBS INVERT -----*/
.invert .bread-crumbs {
  color: rgba(255, 255, 255, 0.55);
}
.invert .bread-crumbs a,
.invert .bread-crumbs a:hover {
  color: rgba(255, 255, 255, 0.75);
}

/*---------------------------*/
/*- INVERT BLOCK ------------*/
.invert-block {
  background: #323232;
  overflow: hidden;
  color: #ccc;
}
.thumbnail .invert-block {
  margin: 20px -20px -20px;
}

/*---------------------------*/
/*- INVERTED MENUS ----------*/
.invert .nav-vertical li a:hover {
  background: none;
  color: #fff;
}
.invert .sub-menu,
.invert .sub-menu li a {
  background: none;
}
.header-site.invert .sub-menu,
.header-site.invert .sub-menu li a {
  background: #fff;
  color: #000;
}
.header-site.invert .sub-menu li a:hover {
  background-color: #323232;
  color: #fff;
}
.invert .responsive-nav .l-menu {
  color: #fff;
}

/*---------------------------*/
/*- INVERTED BUTTONS --------*/

/*-----------------------*/
/*- INVERT LINK BUTTON --*/
.invert-block .btn-link {
  color: #ccc;
}
.invert-block .btn-link:hover {
  color: #fff;
}

/*-----------------------*/
/*- INVERT PRIMARY BUTTON -*/
.invert .btn-primary {
  color: #5e2500;
}
.invert .btn-primary:hover {
  color: #5e2500;
}

/*-----------------------*/
/*- INVERT SUBTLE BUTTON -*/
.invert-block .btn-subtle {
  background-color: #454545;
  color: #fff;
}
.invert-block .btn-subtle:hover {
  background-color: #009eec;
  color: #fff;
}

/*---------------------------*/
/*- INVERT COMMENTS ---------*/
.invert .comment {
  color: rgba(255, 255, 255, 0.75);
}

/*---------------------------*/
/*- FOOTER INVERT -----------*/
.invert .copyright {
  color: rgba(255, 255, 255, 0.55);
}
.footer-site.invert .section-nav-title {
  color: rgba(255, 255, 255, 0.55);
}

.clutch-block {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

.text-capitalize {
  text-transform: capitalize;
}

.privacy-policy ul li {
  list-style: disc;
  padding-left: 0.5em;
  margin-left: 2.5em;
}

.privacy-policy ol li {
  list-style-type: decimal;
  padding-left: 0.5em;
  margin-left: 1em;
  margin-top: 0.5em;
  font-weight: bold;
}

.privacy-policy ol li .c1 {
  font-weight: normal;
  margin-top: 0em;
}

.privacy-policy h2 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.home ul li {
  list-style: disc;
  padding-left: 0.5em;
  margin-left: 2.5em;
}

.home p.description {
  margin-bottom: 1em;
}

.section-title {
  margin-top: 30px;
}

.badges {
  margin-top: 30px;
  //margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 10px;
  right: 30px;
  width: 100%;
}

.badges img {
  width: 120px;
  height: 120px;
  margin-left: 20px;
  object-fit: contain;
}

.section-title.large {
  margin-top: 80px;
  margin-bottom: 80px;
}

@media (max-width: 480px) {
  .section-title.large {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .full-width {
    min-height: 580px;
  }

  .badges {
    justify-content: space-evenly;
    right: 0;
  }

  .badges img {
    margin-left: 0px;

    width: 90px;
    height: 80px;
  }
}

.full-width {
  width: 100%;
  position: relative;
}

.full-width:after {
  display: block;
  height: 120px;
  content: " ";
}

.cross-fade-leave {
  opacity: 1;
}
.cross-fade-leave.cross-fade-leave-active {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.cross-fade-enter {
  opacity: 0;
}
.cross-fade-enter.cross-fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.cross-fade-height {
  transition: height 0.5s ease-in-out;
}

h1.heading:after {
  width: 10%;
  height: 1px;
  background-color: #6c0b14;
  display: block;
  content: " ";

  width: 0%;
  -webkit-transition: width 0s; /* For Safari 3.1 to 6.0 */
  transition: width 0s;
}

h1.heading.show:after {
  width: 100%;
  -webkit-transition: width 5s ease-out; /* For Safari 3.1 to 6.0 */
  transition: width 5s ease-out;
}
.copy-container.main {
  display: inline-block;
}

.copy-container.main h2:nth-child(2) {
  opacity: 0.8;
}
.copy-container.main h2:nth-child(3) {
  opacity: 0.8;
}

.grid3 {
  .fa {
    font-size: 5rem;
    margin-bottom: 0.5rem;
  }
  .media.image-top {
    margin-bottom: 30px;
  }
}


.grid2 {
  .excerpt {
    padding-right: 1.5rem;
  }
}

blockquote.services {
  margin: 5rem 0 3rem;
  border-bottom: 3px solid #811518;
  padding-bottom: 5px;
}
