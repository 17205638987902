@import "../colors";
//@import "../responsive";
@import "../initials";
@import "../mixins";

.post__fetch-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.project-sidebar.static {
  margin-top: 0px;

  @media (min-width: 768px) {
    margin-top: 200px;
  }

  .br {
    display: inline-block;
    width: 5px;

    @media (min-width: 1240px) {
      display: block;
    }
  }

  p {
    font-style: italic;
  }
}

.post__edit-controls-actions {
  display: block;

  span {
    cursor: pointer;
  }

  a {
    color: #717171;
  }
}
.post-meta {


  .client-desc + img {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 1239px) {
    display: flex;
    flex-direction: row-reverse;

    .client-desc {
      margin-left: 20px;

      img {
        display: none;
      }
    }
    .client-desc + img {
      display: block;
    }

    img {
      width: 400px;
    }
  }

/*
  @media (min-width: 1240px) {
    display: flex;
    flex-direction: column;

    .client-desc {
      margin-left: 0px;
    }
  }*/
}
/*
.post {
  font-family: 'Raleway', sans-serif;

  p {
    font-size: calc(17rem / 14);
  }
  label {
    font-size: calc(16rem / 14);
  }
}*/
/*

.post__content {
  font-size: calc(20rem / 14);
  font-weight: 300;
  word-wrap: break-word;
  padding-bottom: 20px;

  @media (max-width: $screen-xs-max) {
    font-size: calc(18rem / 14);
  }

  img {
    max-width: 100%;
  }

  textarea {
    min-height: 300px;
  }
}

.post__content-video {
  margin-top: 30px;
  margin-bottom: 30px;
}

.post__content-bottom-separator {
  border-top: 1px solid $color-p2-lighter;
}

.post__content-related-posts {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $screen-xs-max) {
    display: block;

  }

}

.markdown-content {

  a {
    color: $color-p1-normal;
  }
  em {
    font-style: italic;
  }

  img {
    max-width: 100%;
  }

  blockquote {
    font-size: calc(18rem / 14);
    font-weight: 400;
    border-left: 4px solid $color-p1-normal;
    padding-left: 28px;
  }

  h2 {
    font-size: 2rem;
    font-weight: 400;
    color: $color-p1-normal;
    margin-top: 1.95em;
  }

  hr {
    border-top-color: $color-p2-lighter;
  }

  ol {
    counter-reset: item;

    li {
      padding-left: 30px;
      text-indent: -30px;
      margin-bottom: 10px;
      font-size: calc(17rem / 14);

      @media (max-width: $screen-xs-max) {
        padding-left: 23px;
        text-indent: -23px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: counter(item) ". ";
        counter-increment: item;
        color: $color-p1-normal;
        padding-right: 10px;
        font-size: calc(20rem / 14);
        line-height: 22px;
        vertical-align: text-bottom;
      }
    }
  }

  table {
    overflow: auto;
    font-size: calc(17rem / 14);
    margin: 15px auto 15px;

    tr {
      background-color: #fff;
      border-top: 1px solid $color-p2-lighter;
    }

    th {
      padding: 6px 13px;
      border: 1px solid $color-p2-lighter;
      background-color: fadeout($color-p1-normal, 85%)
    }

    td {
      padding: 6px 13px;
      border: 1px solid $color-p2-lighter;
    }
  }

  ul {
    list-style: none;

    li {
      padding-left: 30px;
      text-indent: -30px;
      margin-bottom: 10px;
      font-size: calc(17rem / 14);

      @media (max-width: $screen-xs-max) {
        padding-left: 23px;
        text-indent: -23px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: "\2022";
        color: $color-p1-normal;
        padding-right: 14px;
        font-size: calc(52rem / 14);
        line-height: 22px;
        vertical-align: text-top;

        @media (max-width: $screen-xs-max) {
          padding-right: 10px;
          font-size: calc(42rem / 14);
          line-height: 22px;
        }
      }
    }
  }
}

.post__static-checkbox {
  float: left;
  display: inline-block;
  width: calc(100% - 165px);
  height: 34px;

  > span {
    vertical-align: text-bottom;
  }
}

.post__date {
  display: inline-block;
  width: 68px;
  float: left;
}

.post__date-top-container {
  text-align: center;
  color: $color-p2-normal;
  font-weight: 500;
  font-size: calc(18rem / 14);
  border: 2px solid $color-p2-light;
  border-bottom: none;
  padding: 4px 0 5px 0;
  border-radius: 3px 3px 0 0;
}

.post__date-bottom-container {
  background-color: $color-p1-normal;
  color: white;
  padding: 3px 0px;
  font-size: calc(12rem / 14);
  font-weight: 500;
  border-radius: 0 0 3px 3px;
  text-align: center;
}

.post__title-text {
  display: inline-block;
  max-width: calc(100% - 88px);
  margin-left: 20px;

  @media (max-width: $screen-xs-max) {
    max-width: 100%;
    margin-top: 20px;
    margin-left: 0;
  }
}

.post__title-block {
  h1 {
    text-align: left;
    display: inline;
    font-weight: 300;
    font-size: 2rem;
  }

  &:not(.post__row-editing) {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.post__row-editing {
  margin-bottom: 10px;
}

.post__fetch-indicator {
  text-align: center;
  margin-top: 80px;

  >p {
    font-size: calc(17rem / 14);
  }
}

.post__fetch-error {
  text-align: center;
  margin-top: 80px;
  padding: 0 20px;
}

.posts__no-posts-error {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0;
}

.tag {
  @include noselect;

  display: inline-block;
  background-color: $color-anchor-default;
  text-align: center;
  margin: 0 4px 5px 0;
  padding: 2px 7px;
  border-radius: 5px;
  font-weight: 700;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: $color-anchor-hover;
  }
}

.post__meta-tag {
  >a {
    //pointer-events: none;
  }
}

.post__meta {

  margin-top: 7px;
  color: $color-p2-lighter;
  @include noselect;

  a {
    vertical-align: baseline;
    color: $color-p2-lighter;
    @include transition(all 0.3s ease-in-out);

    &:hover {
      color: $color-p1-normal;
    }
  }

  >span {
    vertical-align: baseline;
    padding-right: 7px;
    margin-right: 7px;
    border-right: 1px solid $color-p2-lighter;
    cursor: default;
    &:last-child {
      border: none;
      margin-right: 0;
      padding-right: 0;
    }
  }

  .material-icons {
    font-size: calc(16rem / 14);
    vertical-align: text-bottom;
    margin-right: 5px;
    color: $color-p2-lighter;
    cursor: default;
  }

  @media (max-width: $screen-xs-max) {
    display: inline-block;
    max-width: calc(100% - 78px);
    margin-top: 0;
    margin-left: 10px;

    >span {
      padding-right: 0;
      margin-right: 0;
      border: none;
      display: block;
    }
  }
}


.post__edit-controls-voting {
  display: inline-block;

  margin-right: 5px;
}


.comment__edit-controls-actions {

  .material-icons {
    @include transition(all 0.3s ease-in-out);

    color: $color-p2-lighter;

    &:hover {
      color: $color-p1-normal;
    }
  }
}

.comment__edit-controls-voting {

  .material-icons {
    @include transition(all 0.3s ease-in-out);
  }
}

.post__abstract {

  >p {
    font-size: calc(17rem / 14);
    margin-top: 10px;
  }
}

.post__edit-buttons {

  >button {
    float: right;
    &:nth-child(2) {
      margin-right: 10px;
    }
  }
}

.post__error-wrap {
  margin-top: 15px;

  > span {
    font-size: calc(16rem / 14);
  }
}

.post__content-label {
  margin-top: 10px;
  margin-bottom: 5px;
}

.post__main-image {
  padding: 5px 0;
  text-align: center;
  margin: 15px 0;

  >img {
    max-width: 100%;
  }
  >span {
    display: block;
    font-style: italic;
    font-size: calc(18rem / 14);
    text-align: center;
    font-weight: 300;
  }
}

.post__author-block-tile {
  margin-bottom: 40px;
}

.post__author-block-image {
  float: left;
  width: 140px;
  height: 140px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 4px;

  @media (max-width: $screen-xs-max) {
    width: 120px;
    height: 120px;
  }

  @media (max-width: $mobile-md-max) {
    width: 150px;
    height: 150px;
    margin: 0 auto 15px;
    float: none;
  }
}
.post__author-block-title {
  float: left;
  padding-left: 20px;
  width: calc(100% - 140px);
  font-size: calc(22rem / 14);

  @media (max-width: $screen-xs-max) {
    width: calc(100% - 120px);
  }

  @media (max-width: $mobile-md-max) {
    width: 100%;
    padding-left: 0;
    float: none;
    text-align: center;
  }

  >a {
    color: $color-p3-dark;

    &:hover {
      color: $color-p1-normal;
    }
  }
}
.post__author-block-bio {
  float: left;
  padding-left: 20px;
  width: calc(100% - 140px);
  font-size: calc(18rem / 14);
  margin-top: 10px;

  @media (max-width: $screen-xs-max) {
    width: calc(100% - 120px);
  }

  @media (max-width: $mobile-md-max) {
    font-size: calc(16rem / 14);
    float: none;
    width: 100%;
    padding-left: 0;
  }
}

.post__author-block-separator {
  border-top: 1px solid $color-p2-lighter;
  margin-top: 40px;
  margin-bottom: 0;
}

.post__comment-error {
  float: left;
  min-height: 34px;
  display: inline-block;
  width: calc(100% - 175px);
  font-size: calc(16rem / 14);
}

.post__comments-separator {
  height: 1px;
  background-color: $color-p2-lighter;
  margin-bottom: 30px;
}

.post__newComment-label {
  margin-top: 20px;
  margin-bottom: 5px;
}

@mixin post__newComment {

  margin-bottom: 40px;

  h4 {
    margin-bottom: 50px;
    font-size: calc(16rem / 14);
    font-weight: 600;

    &:before {
      content: " ";
      width: 14px;
      height: 14px;
      border-radius: 4px;
      margin-right: 7px;
      background-color: $color-p1-normal;
      display: inline-block;
    }
  }

  >textarea {
    @include default-textarea;
  }
}

.post__newComment {
  @include post__newComment;
}

.post__newComment--inactive {
  @include post__newComment;
}

.post__newComment-error-and-submit {

  margin-top: 10px;

  >button {
    float: right;
  }
}

.post__newComment-error {
  float: left;
  min-height: 34px;
  display: inline-block;
  width: calc(100% - 130px);
  font-size: calc(16rem / 14);
}

.post__comment {
  margin-top: 20px;

  &:first-child {
    margin-top: 40px;
  }
  &:last-child {
    margin-bottom: 50px;
  }
}

.post__comment-tile {
  border: 1px solid $color-p2-lighter;
  border-radius: 4px;
  padding: 30px 25px;


  &.deleting {
    background-color: $color-p2-lighter;
  }

  .wysiwyg-container {
    margin-bottom: 5px;
  }

  &.row {
    margin-left: 0;
    margin-right: 0;
  }

  p {
    font-size: calc(17rem / 14);
  }

  @media (max-width: $mobile-md-max) {
    padding: 20px 15px;
  }
}

.comment__edit-controls {
  display: inline-block;
  float: right;

  .material-icons {
    font-size: calc(18rem / 14);
    cursor: pointer;
    vertical-align: text-top;
    line-height: 28px;
  }
}

.post__comment-image {
  float: left;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 100%;

  @media (max-width: $mobile-md-max) {
    margin: 0 auto 15px;
    width: 60px;
    height: 60px;
  }
}


.post__comment-title {
  float: left;
  padding-left: 25px;
  width: calc(100% - 125px);
  font-size: calc(22rem / 14);

  a {
    color: $color-p3-dark;

    &:hover {
      color: $color-p1-normal;
    }
  }

  p {
    margin-bottom: 0;
    font-size: 1rem;
    color: $color-p1-normal;
  }

  @media (max-width: $mobile-md-max) {
    padding-left: 15px;
    width: calc(100% - 105px);
    word-wrap: break-word;
  }

}
.post__comment-content {
  float: left;
  width: calc(100% - 80px);
  font-size: calc(16rem / 14);
  padding-left: 25px;
  margin-top: 10px;
  word-wrap: break-word;

  @media (max-width: $mobile-md-max) {
    width: 100%;
    padding-left: 0;
    margin-left: 0;
  }
}

.post__comment-edit-buttons-wrap {
  margin-top: 5px;

  >button {
    float: right;

    &:nth-child(2) {
      margin-right: 10px;
    }
  }
}

.shadowed-content {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
}

.shadowed-content--shadow {
  display: block;
  position: absolute;
  width: 100vw;
  height: 100px;
  background: linear-gradient(0deg, rgba(255,255,255,1) 45%, rgba(255,255,255,0) 100%);
  top: -131px;
}*/
