@import 'colors.scss';
@import 'mixins.scss';

$mobile-lg-max: 767px;
$mobile-lg-min: 501px;
$mobile-md-max: 500px;
$mobile-md-min: 401px;

@mixin default-button {
  @include transition(all 0.3s ease-in-out);

  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: calc(11.5rem / 14);
  padding: 8px 30px;
  background-color: $color-p1-normal;
  color: white;
  font-weight: 300;

  &:hover {
    background-color: $color-p3-dark;
    color: white;
  }

  &:focus, &:active, &.focus, &.active.focus, &:active:focus, &.active:focus, &:active.focus {
    background-color: $color-p3-dark;
    color: white;
    outline-color: $color-p1-light;
  }
}

.default-button {
  @include default-button;
}


@mixin default-textarea {
  //font-size: calc(~"14rem / 14");
  width: 100%;
  padding: 10px;
  min-height: 100px;
  outline: none;
  border: 1px solid $color-p2-lighter;
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: vertical;

  @media (max-width: 768px) {
    min-height: 150px;
  }
}


@mixin default-input {
  outline: none;
  border: 1px solid $color-p2-lighter;
  border-radius: 4px;
  padding: 6px 15px;
}

.default-input {
  @include default-input;
}

input[type="checkbox"] {
  &.default-checkbox {
    @include apperance(none);

    color: white;
    //background-color: $color-p1-normal;
    background-color: white;
    border: 1px solid $color-p1-normal;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
    padding: 7px;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    margin-top: 0;
    margin-right: 5px;

    &:disabled {
      background-color: $color-p2-lighter;
    }

    &:focus {
      outline-color: $color-p1-light;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
      outline-style: auto;
    }

    &:active, &:checked:active {
      box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
    }

    &:checked:after {
      content: ' ';
      font-size: 16px;
      position: absolute;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      top: 1px;
      left: 1px;
      background: url('./svg/checkbox-check.svg') !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
    }
  }
}
.yt-container {
  overflow: hidden;
  position: relative;
  width:100%;

  &:after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
