/*-

Description: This is a stylesheet containing styling for specific theme components.

Use this file as a reference for Custom CSS or to build
a child theme color scheme. Do not edit this file directly -
it is not loaded into the theme!!

Author: Obox Themes
Author URI: http://www.oboxthemes.com/
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
*/

/*-------------------------------*/
/*- ADVANCED LAYOUTS ------------*/
/*-------------------------------*/

.advanced-layout {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;

  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.advanced-layout-left header {
  order: 1;
  width: 20%;
}
.advanced-layout-left .wrapper-content {
  order: 2;
  width: 80%;
}
.advanced-layout-right header {
  order: 2;
  width: 20%;
}
.advanced-layout-right .wrapper-content {
  order: 1;
}

/*-------------------------------*/
/*- SLIDER ----------------------*/
/*-------------------------------*/

.swiper-container.container {
  padding: 0px;
}
.swiper-container.loading {
  opacity: 0;
  transition: 0.2s ease-in-out;
}
.swiper-container.loaded {
  transition: 0.2s ease-in-out;
}

/*---------------------------*/
/*- SLIDER DEFAULTS ---------*/
.swiper-slide img {
  display: block;
  margin: auto;
}
.swiper-slide .overlay {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  height: 100%;
  position: relative;
}
.auto-height .swiper-slide .overlay {
  padding-top: 60px;
  padding-bottom: 60px;
}
.swiper-slide .container {
  display: -webkit-flex;
  -webkit-flex-direction: column;
  -webkit-justify-content: center;
  -webkit-align-items: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 0;
}
.swiper-slide .copy-container,
.swiper-slide .image-container {
  width: 100%;
  position: relative;
}
.swiper-slide .section-title {
  margin-bottom: 0;
}
.slide:not(.single-slide) .swiper-slide {
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

/*---------------------------*/
/*- SLIDER HEIGHT -----------*/
.swiper-container.full-screen {
  height: 100vh;
}
.layers-header-overlay
  .wrapper-content
  .widget:first-child.swiper-container
  .container {
  padding-top: 4%;
}

/*---------------------------*/
/*- SLIDER ALIGNMENT --------*/
.swiper-slide.image-top .container {
  flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
}
.swiper-slide .copy-container + .image-container {
  margin-top: 30px;
}
.swiper-slide.image-top .copy-container + .image-container {
  margin-top: 0;
  margin-bottom: 30px;
}
.swiper-slide.image-bottom .overlay {
  flex-direction: column;
  -webkit-flex-direction: column;
}

/*---------------------------*/
/*- SLIDER NAVIGATION -------*/

/*-----------------------*/
/*- PAGINATION ----------*/
.swiper-container .pages {
  position: absolute;
  bottom: 0;
  opacity: 0;
  z-index: 2000;
  width: 100%;
  text-align: center;
}
.swiper-container:hover .pages {
  bottom: 16px;
  opacity: 1;
}
.swiper-container .swiper-pagination-switch {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 8px;
  background: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
  overflow: hidden;
  text-indent: -999px;
  cursor: pointer;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}
.swiper-container .swiper-pagination-switch:hover {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  background: transparent;
  border: 2px solid #fff;
}
.swiper-container
  .swiper-pagination-switch.swiper-visible-switch.swiper-active-switch {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  background: transparent;
  border: 2px solid #fff;
}

/*-----------------------*/
/*- ARROWS --------------*/
.swiper-container .arrows {
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 2000;
  margin-top: -25px;
}
.swiper-container .arrows a {
  position: absolute;
  opacity: 0;
  display: block;
  overflow: hidden;
  color: #fff;
  font-size: 32px;
  line-height: 50px;
}
.swiper-container .arrows .l-left-arrow {
  left: 0;
}
.swiper-container .arrows .l-right-arrow {
  right: 0;
}
.swiper-container:hover .arrows .l-left-arrow {
  left: 10px;
  opacity: 1;
}
.swiper-container:hover .arrows .l-right-arrow {
  right: 10px;
  opacity: 1;
}

/*-------------------------------*/
/*- FEATURE LIST (tick/cross) ---*/
/*-------------------------------*/

.feature-list .media-body .excerpt ol,
.feature-list .media-body .excerpt ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.feature-list .excerpt del,
.feature-list .excerpt s {
  text-decoration: none;
}
.feature-list .excerpt del:before,
.feature-list .excerpt s:before {
  margin-right: 8px;
  content: "\e900";
  font-family: "layers-icons";
  font-size: 1.4rem;
}
.feature-list .excerpt em {
  font-style: normal;
}
.feature-list .excerpt em:before {
  margin-right: 8px;
  content: "\e901";
  font-family: "layers-icons";
  font-size: 1.4rem;
}

/*-------------------------------*/
/*- DARKEN ----------------------*/
/*-------------------------------*/

.darken {
  position: relative;
}
.overlay.darken {
  background: rgba(0, 0, 0, 0.2);
}
:not(.overlay).darken .container:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

/*-------------------------------*/
/*- AVATAR ----------------------*/
/*-------------------------------*/

.avatar {
  display: block;
  position: relative;
}
.avatar .avatar-image {
  display: block;
  float: left;
  margin-right: 10px;
  max-width: 54px;
  border-radius: 100%;
  position: relative;
  z-index: 1;
}
.avatar .avatar-image img {
  display: block;
  margin: auto;
  border-radius: 100%;
}
.avatar .avatar-body {
  overflow: hidden;
  padding: 5px 0;
}
.avatar .avatar-body .avatar-name {
  display: block;
  font-weight: 700;
}
.avatar.middled {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.avatar.middled .avatar-image {
  float: none;
  margin: auto;
}

/*-------------------------------*/
/*- STORY -----------------------*/
/*-------------------------------*/

.story {
  font-size: 1.6rem;
  word-wrap: break-word;
}
.story p {
  margin-bottom: 20px;
}
.story address {
  margin-bottom: 26px;
}
.story a:not(.button) {
  text-decoration: underline;
}
.story ul {
  list-style: disc;
  margin: 0 0 26px 35px;
}
.story ul li {
  list-style: disc;
  margin: 0 0 6px;
}
.story ol {
  list-style: decimal;
  margin: 0 0 26px 35px;
}
.story ol li {
  list-style: decimal;
  margin: 0 0 6px;
}
.story ul ul,
.story ul ol,
.story ol ol,
.story ol ul {
  margin-bottom: 0;
}
.story .feature-list {
  list-style: none;
  margin-left: 15px;
}
.story dl {
  margin: 0 0 26px;
}
.story dd {
  margin-left: 36px;
}

.story img {
  max-width: 100%;
  height: auto;
}
.story img.align-center {
  display: block;
}

.story h1 {
  margin-bottom: 26px;
  font-size: 3.2rem;
  font-weight: 700;
}
.story h2 {
  margin-bottom: 26px;
  font-size: 2.2rem;
  font-weight: 700;
}
.story h3 {
  margin-bottom: 26px;
  font-size: 2rem;
  font-weight: 700;
}
.story h4 {
  margin-bottom: 26px;
  font-size: 1.8rem;
  font-weight: 700;
}
.story h5 {
  margin-bottom: 26px;
  font-size: 1.4rem;
  font-weight: 700;
}
.story h6 {
  margin-bottom: 26px;
  font-size: 1.4rem;
}
.story table {
  font-size: 1.4rem;
}

.story blockquote {
  padding: 20px;
  margin-bottom: 26px;
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.55);
}
.story blockquote p:last-child {
  margin-bottom: 0;
}

/*-----------------------*/
/*- WP CAPTIONS ---------*/
.wp-caption {
  max-width: 100%;
  padding: 10px;
  background: #f0f0f0;
}
.wp-caption-text {
  padding: 10px 5px 5px;
  margin: 0;
  color: #777;
  font-size: 1.4rem;
}
.wp-caption p.wp-caption-text {
  padding: 10px 5px 5px;
  margin: 0;
}

.wp-caption.alignright,
.story img.alignright {
  float: right;
  max-width: 320px;
  margin: -10px 0 10px 20px;
}
.wp-caption.alignleft {
  float: left;
  max-width: 320px;
  margin: 0 20px 10px 0;
}
.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.gallery-caption {
  font-size: 1.2rem;
}
.bypostauthor {
  color: #777;
}

.alignleft {
  float: left;
}
.alignright {
  float: right;
}
.aligncenter {
  clear: both;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.wp-caption.aligncenter {
  margin-bottom: 20px;
}

/*-------------------------------*/
/*- META ------------------------*/
/*-------------------------------*/

/*---------------------------*/
/*- META INFO ---------------*/
.meta-info {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  font-size: 1.2rem;
}
.meta-info + .heading {
  margin-top: 15px;
}
.meta-info p {
  margin: 0;
}
.meta {
  overflow: hidden;
  color: #8f8f8f;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.meta-list {
  overflow: hidden;
}
.meta-list li {
  padding: 5px 0;
  color: #777;
}
div + .meta-list {
  margin-top: 10px;
}
.meta-item {
  display: block;
  margin-top: 2px;
}
.meta-item:first-child {
  margin-top: 0;
}
.meta-item i {
  display: inline-block;
  width: 16px;
}
.meta-info + .heading,
.excerpt + .meta-info {
  margin-top: 15px;
}
.meta-info p {
  margin: 0;
}

/*-----------------------*/
/*- SINGLE PAGE META ----*/
.single .meta-item {
  display: inline-block;
  margin-right: 15px;
}
.single .meta-info-vertical .meta-item {
  display: block;
  margin-right: 0;
}

/*-------------------------------*/
/*- COMMENTS --------------------*/
/*-------------------------------*/

.comment {
  clear: both;
}
.comment .comment {
  margin-top: 20px;
}
.comment .comment:last-child {
  margin-bottom: 0;
}

/*-------------------------------*/
/*- COMMENTS FORM ---------------*/
/*-------------------------------*/

.comment-notes {
  margin-bottom: 20px;
  color: #999;
  font-size: 1.2rem;
}
.comment-respond {
  clear: both;
  margin-top: 6%;
}
.comment-list .comment-respond {
  margin-top: 0;
  padding: 20px;
  background: rgba(0, 0, 0, 0.05);
}
.comment-list .comment .comment-respond {
  margin-top: 20px;
}
.comment-reply-title {
  color: #323232;
  font-size: 2.5rem;
}
.logged-in-as {
  margin-bottom: 20px;
  font-size: 1.2rem;
}
.logged-in-as a {
  color: rgba(0, 0, 0, 0.55);
}
.logged-in-as a:hover {
  text-decoration: underline;
}
p[class*="comment-form"] .required {
  color: #900;
}
p[class*="comment-form"] {
  margin-bottom: 15px;
}
.form-allowed-tags {
  max-width: 660px;
  margin-bottom: 15px;
  font-size: 1.2rem;
}
.form-allowed-tags code {
  padding: 0;
  background: none;
}
.form-submit input[type="submit"] {
  display: inline-block;
  padding: 4px 14px;
  vertical-align: middle;
  background: #454545;
  border: none;
  color: #fff;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.form-submit input[type="submit"]:hover {
  background: #323232;
  color: #fff;
}

/*-------------------------------*/
/*- WORDPRESS WIDGETS -----------*/
/*-------------------------------*/

.sidebar .widget li:not(:last-child) {
  padding: 0 0 6px;
  margin: 0 0 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.sidebar .widget li ul {
  margin: 6px 0 0 10px;
}

.footer-site .widget li {
  margin-bottom: 8px;
}
.footer-site .widget li:last-child {
  border-bottom: none;
}

/*---------------------------*/
/*- WIDGET NAV MENU ---------*/
.widget.widget_nav_menu {
  max-width: none;
  margin: 0;
}
.wrapper-site .menu-main-menu-container {
  max-width: 1040px;
  padding: 20px 0;
  margin: auto;
  overflow: hidden;
  text-align: center;
}
.menu-main-menu-container li {
  display: inline;
  margin: 0 10px;
}
.menu-main-menu-container li a {
  display: inline-block;
  color: #323232;
  text-align: center;
  font-weight: 700;
}
.menu-main-menu-container li a:hover {
  color: #8f8f8f;
}

/*-----------------------*/
/*- WIDGET NAV MENU -----*/
.sidebar .widget.widget_nav_menu {
  margin-bottom: 20px;
}
.sidebar .menu-main-menu-container {
  padding: 0;
}
.sidebar .menu-main-menu-container li {
  display: block;
  font-weight: normal;
}
.sidebar .menu-main-menu-container li a {
  display: block;
  text-align: left;
}

/*---------------------------*/
/*- SEARCH WIDGET -----------*/
.screen-reader-text {
  display: none;
}
.search-form {
  overflow: hidden;
}
.search-form label {
  margin-bottom: 0;
}
aside .search-submit {
  display: block;
  width: 100%;
}
aside .search-field {
  margin-bottom: 5px;
}

/*-----------------------*/
/*- SEARCH IN FOOTER ----*/
.footer-site .search-form label {
  width: 70%;
  float: left;
}
.footer-site .search-form .search-field {
  border-radius: 4px 0 0 4px;
}
.footer-site .search-submit {
  width: 30%;
  float: left;
  height: 36px;
  border-radius: 0 4px 4px 0;
}

/*---------------------------*/
/*- CALENDAR WIDGET ---------*/
#wp-calendar {
  font-size: 1.2rem;
}
#wp-calendar caption {
  text-align: left;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
}
#wp-calendar th,
#wp-calendar td {
  padding: 5px;
}

/*-------------------------------*/
/*- MAP - ADD ON ----------------*/
/*-------------------------------*/

.layers-contact-widget img.google-map-img {
  width: 100%;
}
.row.has-map {
  padding-top: 0;
}
.layers-map img {
  width: auto;
  max-width: none;
  display: block;
}

/*-------------------------------*/
/*- TOOLTIPS --------------------*/
/*-------------------------------*/

.tooltip {
  cursor: help;
  position: relative;
}
.tooltip:after {
  width: 220px;
  padding: 15px;
  border-radius: 4px;
  position: absolute;
  left: -15px;
  bottom: 40px;
  z-index: 98;
  background: rgba(0, 0, 0, 0.85);
  content: attr(title);
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.tooltip:before {
  border: solid;
  content: "";
  position: absolute;
  left: 15px;
  bottom: 34px;
  z-index: 99;
  border: solid;
  border-color: rgba(0, 0, 0, 0.85) transparent;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.tooltip:hover:after {
  opacity: 1;
  visibility: visible;
  bottom: 35px;
}
.tooltip:hover:before {
  opacity: 1;
  visibility: visible;
  bottom: 29px;
}

/*-------------------------------*/
/*- POPULAR WP PLUGINS ----------*/
/*-------------------------------*/

/*---------------------------*/
/*- CONTACT FORM 7 ----------*/
.wpcf7-form p {
  margin-bottom: 15px;
  vertical-align: top;
  font-weight: bold;
}
.wpcf7-form br {
  display: none;
}
.wpcf7-form-control-wrap {
  display: block;
  margin-top: 4px;
  font-weight: normal;
}

/*-------------------------------*/
/*- INVERT COMPONENTS -----------*/
/*-------------------------------*/

.invert .meta-info {
  color: rgba(255, 255, 255, 0.65);
}
.invert .meta-info a {
  color: rgba(255, 255, 255, 0.85);
}
.invert .meta-info a:hover {
  color: rgba(255, 255, 255, 1);
}

/*---------------------------*/
/*- NOTICE STYLING ----------*/

.success {
  display: inline-block;
  padding: 5px 10px;
  background: #fff;
  border: 1px solid #a3c567;
  font-weight: bold;
  color: #a3c567;
  border-left: 4px solid #a3c567;
}

/*-----------------------*/
/*- STATUS NOTICE -------*/
.status-notice {
  display: block;
  margin-bottom: 10px;
  border: 1px solid;
  color: #fff;
  font-weight: bold;
  overflow: hidden;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  line-height: 32px;
}
a.status-notice:hover {
  color: #fff;
  text-decoration: none;
}
a.status-notice:hover span {
  text-decoration: underline;
}
.status-notice [class^="icon-"],
.status-notice [class*=" icon-"] {
  float: left;
  width: 32px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 14px;
  line-height: 32px;
}
.status-notice-heading {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
}
.status-notice-heading span {
  float: left;
  padding: 10px 0 10px 8px;
  overflow: hidden;
}

/*-------------------*/
/*- STATUS COLORS ---*/
.status-good {
  background: #a3c567;
  border-color: #93b25e;
}
.status-neutral {
  background: #2ea2cc;
  border-color: #0074a2;
}
.status-bad {
  background: #e1574c;
  border-color: #b0453d;
}

/*-------------------------------*/
/*- RESPONSIVE COMPONENTS -------*/
/*-------------------------------*/
@media only screen and (max-width: 480px) {
  .swiper-container .arrows {
    display: none;
  }
}

@media only screen and (min-width: 769px) {
  .sidebar .search-field {
    width: 65%;
    float: left;
  }
  .sidebar .search-submit {
    float: left;
    width: 35%;
    height: 36px;
  }
}

.slick-slide .logotype {
  width: 300px;
  height: 200px;
  object-fit: scale-down;
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  top: 0;
  left: 0;
}

.slick-track:after,
.slick-track:before {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}
.slick-track:after,
.slick-track:before {
  display: table;
  content: "";
}

.slick-initialized .slick-slide {
  display: block;

  @media (max-width: 600px) {
    padding: 5px;
  }
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slider-container {
  max-width: 1000px;
  margin: auto;
}

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
}

.slick-next:before,
.slick-prev:before {
  color: #811518;
}
.slick-prev:before {
  content: "\f060";
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
}
.slick-next:before,
.slick-prev:before {
  font-family: FontAwesome;
  font-size: 20px;
  line-height: 1;
  //opacity: 0.75;
  color: #811518;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-next:before {
  content: "\f061";
}
.slick-prev {
  left: -25px;
}

.slick-next {
  right: -25px;
}
.slick-next,
.slick-next:focus,
.slick-next:hover,
.slick-prev,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: none;
  background: transparent;
}

@media (max-width: 600px) {
  .proof .slick-next {
    right: 40%;
    top: 100%;
  }
  .proof .slick-prev {
    left: 40%;
    top: 100%;
  }
}
