@import "./../colors";

.eguide-cta-container {
  border: 1px solid $color-p1-normal;
  padding: 15px;
  cursor: pointer;
  text-align: center;
}

.eguide-cta__image {
  border: none !important;
  max-width: 50px !important;
}

.eguide--images-wrapper {
  margin: 10px 40px;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}

.enguide--images-container {
  display: inline-flex;
  align-items: center;

  width: 100%;

  @media (min-width: 480px) {
    width: 50%;
  }

  @media (min-width: 769px) {
    width: 30%;
  }

  h3 {
    margin-left: 10px;
  }
}

.eguide--shorts {
  p {
    font-size: 1.4em;
    color: $color-p1-dark;
  }

  strong {
    font-size: 1.8em;
  }
}