@mixin transition($x...){
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}
//
//@mixin keyframes($name, $arguments) {
//  @-moz-keyframes $name { $arguments(); }
//  @-webkit-keyframes $name { $arguments(); }
//  @keyframes @name { $arguments(); }
//}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($arguments) {
  -webkit-animation: #{$arguments};
  -moz-animation: #{$arguments};
  animation: #{$arguments};
}

@mixin apperance ($value) {
  -webkit-appearance: $value;
  -moz-appearance:    $value;
  appearance:         $value;
}