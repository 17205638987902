@import "futr/index";

@import "loading-screen";

@import "home/slider";

body {
  font-family: "Roboto", Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading {
  font-family: "Alegreya Sans", Helvetica, sans-serif;
  color: $color-p1-normal;
}

.header-site.invert .nav-horizontal > ul > li > a,
.header-site .nav-horizontal > ul > li > a,
.header-search a {
  border-radius: 4px;
}

input[type="button"],
input[type="submit"],
button,
.button,
.form-submit input[type="submit"] {
  border-width: 0px;
  border-radius: 4px;
  pointer-events: auto;
}

.invert input[type="button"],
.invert input[type="submit"],
.invert button,
.invert .button,
.invert .form-submit input[type="submit"] {
  border-width: 0px;
  border-radius: 0px;
}

.header-site,
.header-site.header-sticky {
  background-color: #ffffff;
}

.footer-site {
  background-color: #ffffff;
}

.sidebar .well {
  background-color: #ffffff;
}

.sitetitle,
.tagline {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
}

.sub-menu li.menu-item a:hover {
  background-color: $color-p1-light;
}

li.menu-item.highlighted a {
  color: $color-p1-normal;

  &:hover {
  }
}

img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}
#wp-admin-bar-layers-edit-layout .ab-icon:before {
  font-family: "layers-interface" !important;
  content: "\e62f" !important;
  font-size: 16px !important;
}

.media-logotype {
  height: 100%;
  display: flex;

  .media-image {
    margin: auto;

    .logotype {
      width: 75%;
    }
  }
}
/*

.story {
  #section-our-story {
    video,
    img {
      border-bottom: 1px solid $color-p1-normal;
    }
  }
}
*/

.story {
  video,
  img {
    width: 100%;
    padding-bottom: 1em;
    border-bottom: 1px solid $color-p1-normal;
  }

  .lead {
    margin-top: 6%;
  }

  &.portfolio {
    .gallery-columns-2 dl {
      float: left;
      margin-top: 10px;
      text-align: center;
      width: 50%;
    }

    img {
      border: 2px solid #cfcfcf;
      padding-bottom: 0;
    }
  }
}

$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite,
    colors ($duration * 4) ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: $color-p1-light;
  }
  50% {
    stroke: $color-p1-normal;
  }
  100% {
    stroke: $color-p1-light;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset / 4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

.mail-sent-ok {
  border: 2px solid #398f14;
  margin: 2em 0.5em 1em;
  padding: 0.2em 1em;
  font-size: 2rem;
}

.email-sent-errors {
  border: 2px solid #f7e700;
  margin: 2em 0.5em 1em;
  padding: 0.2em 1em;
  font-size: 2rem;
}

.not-valid-tip {
  color: #f00;
  font-size: 1em;
  font-weight: normal;
  display: block;
}

@import "modal";

@import "./update-notification";

@import "./home/banner";
@import "./Portfolio/portfolio";
@import "./NewPost/new-post";
@import "./Post/post";
@import "./Eguide/style";
@import "./Timeline/timeline";
@import "./Tools/extractor";
