@import "./../initials";
@import "./../colors";

.ext {
  position: relative;
}

.grid--extractor {
  .column {
    @media (max-width: $mobile-lg-max) {
      display: block;
      width: 100%;
    }
  }

  .extractor-arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    .fa-arrow-right {
      display: block;
      @media (max-width: $mobile-lg-max) {
        display: none;
      }
    }

    .fa-arrow-down {
      display: none;
      @media (max-width: $mobile-lg-max) {
        display: block;
      }
    }
  }
}

.ext-dd--section-dropzone {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed;
  cursor: pointer;

  &.has-error {
    border-color: $color-p1-light;
  }
}

.ext-dd {
  &:first-child {
    display: flex;
    flex-direction: column;
  }
}

.ext-summary--dt-wrapper {
}

.ext-summary--expand-button {
  display: block;
  margin: 20px auto;

  border-width: 0px;
  border-radius: 4px;
  pointer-events: auto;

  background-color: $color-p1-normal;
  background-color: $color-p1-normal;
  padding: 10px 20px;

  &:hover {
    background-color: #323232;
    color: #fff;
  }
}

.ext-dd--section-label {
  text-align: center;
}

.ext-summary--totals {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 20px;

}

.ext-summary--totals_value {
  font-weight: 700;
  color: $color-p1-dark;
}

.ext-error {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: $color-p3-dark;

  & ~ .ext-dd--section-dropzone {
    border-color: red;
  }
}

.ext-summary-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.ext-summary-options button {
  display: block;
  border-width: 0px;
  border-radius: 4px;
  pointer-events: auto;
  padding: 10px 20px;

  margin-right: 10px;
  white-space: nowrap;

}

.ext-summary-options {

  display: flex;
  flex-direction: row;

  :last-child {
    margin-right: 0px;
  }
}

.ext-summary-options input {
  display: block;
  border-width: 0px;
  border-radius: 4px;
  pointer-events: auto;
  padding: 10px 20px;
}

.ext-summary-feedback {
  p {

  }
}

.ext-summary-feedback-buttons {
  button {
    margin-right: 5px;
  }

  &:first-child {

  }
}

.ext-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #adadada1;
}
