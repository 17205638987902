@import 'colors';

.fakeloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: $color-bg;
  z-index: 999;

  .fl {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 200px;
    }
  }
}

