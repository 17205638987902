@import "../initials";

.portfolio-meta {
  @media (min-width: 768px) and (max-width: 1239px) {
    display: flex;

    img {
      width: 300px;
    }

    .client-logo + div {
      margin-left: 20px;
    }
  }
}

.gallery-wrapper {
  margin-left: auto;
  margin-right: auto;
}

//@media (max-width: $mobile-lg-max) {
  .ReactGridGallery_tile-viewport img {
    width: 100% !important;
    height: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    object-fit: contain;
  }

  .ReactGridGallery_tile {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
  }

  .ReactGridGallery_tile-viewport {
    width: 100% !important;
    height: 100% !important;
  }
//}

.ReactGridGallery_tile {
  background-color: transparent !important;
}
