.layers-slider-widget {

  width: 100%;
  //height: 600px;
  display: flex;
  align-items: center;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
  transition-duration: 0ms;

  background-color: #6C0B14;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

 // background-blend-mode: lighten;

  &.contact {
    height: 400px;
  }
}
