/*-

Description: This is a stylesheet containing styling for mobile devices.

Use this file as a reference for Custom CSS or to build
a child theme color scheme. Do not edit this file directly -
it is not loaded into the theme!!

Author: Obox Themes
Author URI: http://www.oboxthemes.com/
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
*/

@media (max-width: 480px) {
  /*-------------------------------*/
  /*- COLUMNS ---------------------*/

  /*---------------------------*/
  /*- SPAN - 2 GOES SPAN 6 ----*/
  .span-2 {
    width: calc(((100% / 12) * 6) - 20px);
  }
  .column-flush.span-2 {
    width: calc(((100% / 12) * 6));
  }

  /*---------------------------*/
  /*- CLEARING COLS - FALLBACK */
  .row .column.span-1:nth-child(6n + 7),
  .row .column.span-2:nth-child(2n + 3) {
    clear: left;
  }

  /*---------------------------*/
  /*- REST GO SPAN 12 ---------*/
  .span-3,
  .span-4,
  .span-5,
  .span-6 {
    width: calc(((100% / 12) * 12) - 20px);
  }
  .column-flush.span-3,
  .column-flush.span-4,
  .column-flush.span-5,
  .column-flush.span-6 {
    width: calc(((100% / 12) * 12));
  }

  /*---------------------------*/
  /*- MASONRY -----------------*/
  .list-masonry .grid {
    margin-left: 10px;
    margin-right: -10px;
  }

  /*-------------------------------*/
  /*- NAVIGATION ------------------*/

  /*-------------------------------*/
  /*- HEADER RIGHT REPSONSIVE NAV -*/
  .header-right .responsive-nav {
    margin-left: 0;
  }

  /*---------------------------*/
  /*- TABBED NAV --------------*/
  .nav-tabs {
    padding: 5px;
  }
  .nav-tabs li {
    width: 50%;
    padding: 5px;
    margin: 0;
  }

  /*---------------------------*/
  /*- VERTICAL NAV SUB MENUS --*/
  .nav-vertical {
    display: block;
  }
  .nav-vertical .sub-menu {
    width: auto;
    position: static;
    visibility: visible;
    opacity: 1;
    left: auto;
    right: auto;
    top: auto;
    box-shadow: none;
    border: none;
    font-size: 1.2rem;
  }
  .nav-vertical .sub-menu .sub-menu {
    margin-left: 10px;
  }
  .nav-vertical .sub-menu li {
    border: none;
    width: auto;
  }

  /*-------------------------------*/
  /*- SLIDER ----------------------*/

  .swiper-slide .section-title.large .heading {
    font-size: 3rem;
  }
  .swiper-slide .section-title.large .excerpt {
    font-size: 2rem;
  }

  /*-------------------------------*/
  /*- CONTENT ---------------------*/

  /*---------------------------*/
  /*- META INFO ---------------*/
  .single .meta-item {
    display: block;
  }

  /*-------------------------------*/
  /*- COMPONENTS ------------------*/

  /*---------------------------*/
  /*- MEDIA BLOCK -------------*/
  .media.image-right .media-image,
  .media.image-left .media-image {
    float: none;
    max-width: none;
    margin: 0 0 20px;
  }

  /*-------------------------------*/
  /*- FOOTERS ---------------------*/

  /*---------------------------*/
  /*- COPYRIGHT ---------------*/
  .copyright .column {
    margin-bottom: 10px;
  }
  .copyright .column:last-child {
    margin-bottom: 0;
  }
  .copyright .logo {
    display: none;
  }
  .copyright .t-right {
    text-align: left;
  }
  .copyright .pull-right {
    float: none;
  }
  .copyright .nav-horizontal {
    margin-top: 10px;
  }
  .copyright .nav-horizontal li {
    margin: 0 10px 0 0;
  }
}

/*--------------------------------------------------------------*/
/*- EVERYTHING BIGGER THAN A PHONE -----------------------------*/
/*--------------------------------------------------------------*/
@media (min-width: 481px) {
  /*---------------------------*/
  /*- SPAN - 4 ----------------*/
  .span-4 {
    width: calc(((100% / 12) * 4) - 20px);
  }
  .column-flush.span-4 {
    width: calc((100% / 12) * 4);
  }

  /*---------------------------*/
  /*- SPAN - 6 ----------------*/
  .span-5,
  .span-6 {
    width: calc(((100% / 12) * 6) - 20px);
  }
  .column-flush.span-5,
  .column-flush.span-6 {
    width: calc((100% / 12) * 6);
  }

  /*---------------------------*/
  /*- HEADERS -----------------*/
  .header-secondary {
    display: block;
  }

  /*---------------------------*/
  /*- MEDIA BLOCK -------------*/

  /*-----------------------*/
  /*- IMAGE LEFT / RIGHT --*/
  .image-left .media-image {
    float: left;
    max-width: 49%;
    margin-right: 20px;
  }
  .image-right .media-image {
    float: right;
    max-width: 49%;
    margin-right: 0;
    margin-left: 20px;
  }
  .image-left .media-body,
  .image-right .media-body {
    margin-top: 0;
  }

  /*-------------------*/
  /*- CORRECT WIDTHS --*/
  .span-3 .image-left .media-image,
  .span-4 .image-left .media-image {
    max-width: 33%;
  }
  .span-3 .image-right .media-image,
  .span-4 .image-right .media-image {
    max-width: 33%;
  }
}

/*--------------------------------------------------------------*/
/*-  MOBILE LANDSCAPE MODES ------------------------------------*/
/*--------------------------------------------------------------*/
@media (min-width: 481px) and (max-width: 640px) {
  /*---------------------------*/
  /*- MEDIA BLOCK -------------*/

  /*-----------------------*/
  /*- IMAGE LEFT / RIGHT --*/
  .image-left .media-image,
  .image-right .media-image {
    float: none;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
  .span-3 .image-left .media-image,
  .span-4 .image-left .media-image,
  .span-3 .image-right .media-image,
  .span-4 .image-right .media-image {
    max-width: none;
  }
  .image-left .media-body,
  .image-right .media-body {
    margin-top: 10px;
  }
}

/*--------------------------------------------------------------*/
/*-  AWKWARD SIZE BETWEEN PHONE LANDSCAPE AND TABLET -----------*/
/*--------------------------------------------------------------*/
@media (min-width: 481px) and (max-width: 768px) {
  /*---------------------------*/
  /*- SPAN - 2 GOES SPAN 4 ----*/
  .span-2 {
    width: calc(((100% / 12) * 4) - 20px);
  }
  .column-flush.span-2 {
    width: calc((100% / 12) * 4);
  }

  /*---------------------------*/
  /*- SPAN - 3 GOES SPAN 6 ----*/
  .span-3 {
    width: calc(((100% / 12) * 6) - 20px);
  }
  .column-flush.span-3 {
    width: calc((100% / 12) * 6);
  }

  /*-----------------------*/
  /*- MULTI-SIDEBAR -------*/
  /* This CSS caters for 3 column sidebar layouts */
  .span-3 + .span-6 {
    width: calc(((100% / 12) * 12) - 20px);
  }

  /*-----------------------*/
  /*- DBL-SIDEBAR ---------*/
  /* This CSS caters for 2 column sidebar layouts */
  .span-8 {
    width: calc(((100% / 12) * 12) - 20px);
    /* Set the main column to fullwidth */
  }
  .column.sidebar {
    display: flex;
    flex-flow: row wrap;
    min-width: calc((100% / 12) * 12);
    margin-left: 0px;
    margin-right: 0px;
    /* Set the sidebar to full width and apply flexbox */
  }
  .column.sidebar .widget {
//    flex: 0 0 calc(((100% / 12) * 6) - 20px);
    margin: 0 10px 20px;
    /* align widgets in rows of 2 */
  }

  /*---------------------------*/
  /*- SPAN - 9 GOES SPAN 12 ---*/
  .span-9 {
    width: calc(((100% / 12) * 12) - 20px);
  }
}

/*--------------------------------------------------------------*/
/*-  AWKWARD SIZE BETWEEN PHONE LANDSCAPE AND TABLET -----------*/
/*--------------------------------------------------------------*/
@media (min-width: 601px) and (max-width: 782px) {
  /*---------------------------*/
  /*- CLEARING COLS - FALLBACK */
  .row .column.span-2:nth-child(3n + 4),
  .row .column.span-3:nth-child(2n + 3) {
    clear: left;
  }

  /*-----------------------*/
  /*- HEADER IN ADMIN MODE */
  .admin-bar .header-sticky.is_stuck.is_stuck_show {
    margin-top: 46px;
  }

  /*---------------------------*/
  /*- HEADERS -----------------*/
  .header-secondary .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/*--------------------------------------------------------------*/
/*- EVERYTHING UP TO TABLET ------------------------------------*/
/*--------------------------------------------------------------*/
@media (max-width: 768px) {
  /*---------------------------*/
  /*- MASONRY -----------------*/
  .container.list-masonry {
    padding: 0 10px;
  }

  /*---------------------------*/
  /*- HEADER INLINE -----------*/
  .header-inline .container {
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .header-inline .inline-site-logo {
    display: -webkit-flex;
    -webkit-flex: 1;

    display: flex;
    flex: 1;
  }
  .header-inline .inline-right-nav {
    display: -webkit-flex;
    -webkit-flex-direction: row-reverse;

    display: flex;
    flex-direction: row-reverse;
  }
  .header-inline .inline-left-nav,
  .header-site.header-inline nav {
    display: none;
  }

  .pull-left,
  .pull-right {
    float: none;
  }

  /*---------------------------*/
  /*- LAYERS BADGE ------------*/
  .created-using-layers {
    display: block;
    margin: 20px 0 -20px;
    padding: 5px 10px;
    background: rgba(0, 157, 243, 1);
    color: #fff;
    font-size: 0.9rem;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
  }
  .created-using-layers:hover {
    color: #fff;
    background: #3d454c;
  }

  /*---------------------------*/
  /*- FORM ROWS ---------------*/
  .form-row {
    margin-bottom: 15px;
  }

  /*---------------------------*/
  /*- CLOSE OFF CANVAS --------*/
  .admin-bar section[class*=" off-canvas-"] .close-canvas {
    top: 46px;
  }

  /*---------------------------*/
  /*- OFF CANVAS SIDEBAR ------*/
  .admin-bar div[class*=" off-canvas-"] {
    padding-top: 47px;
  }
}

/*--------------------------------------------------------------*/
/*- BETWEEN TABLET AND DESKTOP ---------------------------------*/
/*--------------------------------------------------------------*/
@media (min-width: 601px) and (max-width: 860px) {
  /*---------------------------*/
  /*- SPAN - 10 ---------------*/
  .span-10 {
    width: calc(((100% / 12) * 12) - 20px);
  }

  /*---------------------------*/
  /*- COMBINATIONS ------------*/
  .span-5 + .span-5 + .span-2 {
  }
  .span-7 + .span-5 {
  }
  .span-10 + .span-2 {
  }

  /*---------------------------*/
  /*- COMBINATIONS ------------*/
  .span-5 + .span-5 + .span-2 {
    width: 100%;
  }
}

/*--------------------------------------------------------------*/
/*- EVERYTHING BIGGER THAN TABLETS -----------------------------*/
/*--------------------------------------------------------------*/
@media (min-width: 769px) {
  /*-------------------------------*/
  /*- GRIDS -----------------------*/

  /*---------------------------*/
  /*- COLUMN WIDTHS -----------*/
  .span-1 {
    width: calc((100% / 12) - 20px);
  }
  .span-2 {
    width: calc(((100% / 12) * 2) - 20px);
  }
  .span-3 {
    width: calc(((100% / 12) * 3) - 20px);
  }
  .span-5 {
    width: calc(((100% / 12) * 5) - 20px);
  }
  .span-7 {
    width: calc(((100% / 12) * 7) - 20px);
  }
  .span-8 {
    width: calc(((100% / 12) * 8) - 20px);
  }
  .span-9 {
    width: calc(((100% / 12) * 9) - 20px);
  }
  .span-10 {
    width: calc(((100% / 12) * 10) - 20px);
  }
  .span-11 {
    width: calc(((100% / 12) * 11) - 20px);
  }

  /*---------------------------*/
  /*- FLUSH COLUMNS -----------*/
  .column-flush.span-1 {
    width: calc(100% / 12);
  }
  .column-flush.span-2 {
    width: calc((100% / 12) * 2);
  }
  .column-flush.span-3 {
    width: calc((100% / 12) * 3);
  }
  .column-flush.span-5 {
    width: calc((100% / 12) * 5);
  }
  .column-flush.span-7 {
    width: calc((100% / 12) * 7);
  }
  .column-flush.span-8 {
    width: calc((100% / 12) * 8);
  }
  .column-flush.span-9 {
    width: calc((100% / 12) * 9);
  }
  .column-flush.span-10 {
    width: calc((100% / 12) * 10);
  }
  .column-flush.span-11 {
    width: calc((100% / 12) * 11);
  }

  /*---------------------------*/
  /*- GUTTERS -----------------*/
  .offset-1 {
    margin-right: 8.5%;
  }
  .offset-2 {
    margin-right: 17%;
  }
  .offset-3 {
    margin-right: 25.5%;
  }
  .offset-4 {
    margin-right: 34%;
  }
  .offset-5 {
    margin-right: 42.5%;
  }
  .offset-6 {
    margin-right: 51%;
  }
  .offset-7 {
    margin-right: 59.5%;
  }
  .offset-8 {
    margin-right: 68%;
  }
  .offset-9 {
    margin-right: 76.5%;
  }
  .offset-10 {
    margin-right: 85%;
  }
  .offset-11 {
    margin-right: 93.5%;
  }

  .offset-left-1 {
    margin-left: 8.5%;
  }
  .offset-left-2 {
    margin-left: 17%;
  }
  .offset-left-3 {
    margin-left: 25.5%;
  }
  .offset-left-4 {
    margin-left: 34%;
  }
  .offset-left-5 {
    margin-left: 42.5%;
  }
  .offset-left-6 {
    margin-left: 51%;
  }
  .offset-left-7 {
    margin-left: 59.5%;
  }
  .offset-left-8 {
    margin-left: 68%;
  }
  .offset-left-9 {
    margin-left: 76.5%;
  }
  .offset-left-10 {
    margin-left: 85%;
  }
  .offset-left-11 {
    margin-left: 93.5%;
  }

  .pull-left-1 {
    margin-left: -8.5%;
  }
  .pull-left-2 {
    margin-left: -17%;
  }
  .pull-left-3 {
    margin-left: -25.5%;
  }
  .pull-left-4 {
    margin-left: -34%;
  }
  .pull-left-5 {
    margin-left: -42.5%;
  }
  .pull-left-6 {
    margin-left: -51%;
  }
  .pull-left-7 {
    margin-left: -59.5%;
  }
  .pull-left-8 {
    margin-left: -68%;
  }
  .pull-left-9 {
    margin-left: -76.5%;
  }
  .pull-left-10 {
    margin-left: -85%;
  }
  .pull-left-11 {
    margin-left: -93.5%;
  }

  .pull-right-1 {
    margin-right: -8.5%;
  }
  .pull-right-2 {
    margin-right: -17%;
  }
  .pull-right-3 {
    margin-right: -25.5%;
  }
  .pull-right-4 {
    margin-right: -34%;
  }
  .pull-right-5 {
    margin-right: -42.5%;
  }
  .pull-right-6 {
    margin-right: -51%;
  }
  .pull-right-7 {
    margin-right: -59.5%;
  }
  .pull-right-8 {
    margin-right: -68%;
  }
  .pull-right-9 {
    margin-right: -76.5%;
  }
  .pull-right-10 {
    margin-right: -85%;
  }
  .pull-right-11 {
    margin-right: -93.5%;
  }

  /*---------------------------*/
  /*- CLEARING COLS - FALLBACK */
  .row .span-2:nth-child(6n + 7),
  .row .span-3:nth-child(4n + 5),
  .row .span-4:nth-child(3n + 4),
  .row .span-6:nth-child(2n + 3) {
    clear: left;
  }

  /*-------------------------------*/
  /*- HEADERS ---------------------*/

  .header-site .container {
    padding: 20px;
  }

  /*---------------------------*/
  /*- NAV DISPLAY -------------*/
  .nav-horizontal ul {
    display: inline-block;
  }
  .responsive-nav {
    display: none;
  }

  /*---------------------------*/
  /*- ALIGNMENT CENTER --------*/
  .header-center {
    text-align: center;
  }
  .header-center .container,
  .header-center > .header-block {
    flex-direction: column;
    -webkit-flex-direction: column;
  }
  .header-center .site-description {
    display: block;
    margin: 0;
  }
  .header-center .site-logo-link + .site-description {
    margin: 10px 0 0;
  }
  .header-center .site-logo-link + .tagline {
    margin-top: 10px;
  }
  .header-center nav {
    margin-top: 20px;
    text-align: center;
    line-height: 20px;
  }

  /*---------------------------*/
  /*- ALIGNMENT INLINE --------*/
  .header-inline .container,
  .header-inline > .clearfix {
    -webkit-justify-content: center;
    justify-content: center;
  }
  .header-inline .inline-site-logo {
    display: -webkit-flex;
    -webkit-justify-content: center;

    display: flex;
    justify-content: center;

    width: 20%;
    margin: 0 20px;
    z-index: 1;
  }

  .header-inline .logo {
    text-align: center;
  }
  .header-inline .site-description {
    display: block;
    margin: 0;
  }
  .header-inline .site-logo-link + .site-description {
    margin-top: 10px;
  }

  .header-inline .inline-left-nav {
    display: -webkit-flex;
    -webkit-justify-content: flex-end;

    display: flex;
    justify-content: flex-end;

    width: 40%;
    position: relative;
    z-index: 2;
  }
  .header-inline .inline-right-nav {
    display: -webkit-flex;
    -webkit-justify-content: flex-start;
    -webkit-align-items: center;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 40%;
    position: relative;
    z-index: 2;
  }
  .header-inline .inline-right-nav nav {
    display: block;
  }

  /*---------------------------*/
  /*- NAV BOTTOM --------------*/
  .nav-clear .container,
  .nav-clear > .header-block {
    -webkit-flex-direction: column;
    -webkit-align-items: flex-start;

    flex-direction: column;
    align-items: flex-start;
  }
  .nav-clear .site-logo-link + .sitename {
    margin-top: 10px;
  }
  .nav-clear .site-logo-link + .tagline {
    margin-top: 10px;
  }
  .nav-clear nav {
    width: 100%;
    margin: 20px 0 0;
    line-height: 20px;
  }

  /*---------------------------*/
  /*- STICKY HEADER VARIATIONS */

  /*-----------------------*/
  /*- HEADER CENTER -------*/
  .header-sticky.is_stuck.header-center .logo {
    display: none;
  }
  .header-sticky.is_stuck.header-center nav {
    margin-top: 0;
  }

  /*-----------------------*/
  /*- NAV BOTTOM ----------*/
  .header-sticky.is_stuck.nav-clear .logo {
    display: none;
  }
  .header-sticky.is_stuck.nav-clear nav {
    margin-top: 0;
  }

  /*-----------------------*/
  /*- HEADER INLINE -------*/
  .header-sticky.is_stuck.header-inline .site-description {
    display: none;
  }

  /*-----------------------*/
  /*- HEADER IN ADMIN MODE */
  .admin-bar .header-sticky.is_stuck.is_stuck_show {
    margin-top: 30px;
  }

  /*-------------------------------*/
  /*- THUMBNAILS ------------------*/

  /*---------------------------*/
  /*- OVERLAY -----------------*/
  .with-overlay {
    overflow: hidden;
  }
  .with-overlay .thumbnail-body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s all ease-in-out;
    text-align: center;
  }
  .with-overlay .image-rounded + .thumbnail-body {
    border-radius: 100%;
  }
  .with-overlay .overlay {
    display: block;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .with-overlay .thumbnail-body {
    background: rgba(0, 0, 0, 0.65) !important;
  }
  .with-overlay .heading {
    opacity: 0;
    color: #fff;
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    transition: 0.2s all ease-in-out 0.1s;
  }
  .with-overlay .heading a {
    color: #fff;
  }

  .with-overlay .button {
    margin-top: 15px;
    position: relative;
    opacity: 0;
    background: none;
    border: 2px solid #fff;
    position: relative;
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    transition: transform 0.2s ease-in-out 0.2s, opacity 0.2s ease-in-out 0.25s;
  }

  .with-overlay .excerpt {
    display: none;
  }
  .with-overlay .meta-info {
    display: none;
  }

  /*-----------------------*/
  /*- OVERLAY HOVER -------*/
  .with-overlay:hover .thumbnail-media img {
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
  }
  .with-overlay:hover .thumbnail-body {
    visibility: visible;
    opacity: 1;
  }
  .with-overlay:hover .heading {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  .with-overlay:hover .button {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  /*-----------------------*/
  /*- MEDIA SMALL ---------*/
  .has-image .image-left.small .media-body,
  .has-image .image-right.small .media-body {
    margin-top: 0;
  }

  /*-------------------------------*/
  /*- OFF CANVAS MENU -------------*/

  /*---------------------------*/
  /*- OFF CANVAS MENU ---------*/
  section[class*=" off-canvas-"] .nav-mobile {
    display: none;
  }

  /*-------------------------------*/
  /*- SLIDER ----------------------*/

  /*---------------------------*/
  /*- SLIDER CONTENT ----------*/
  .swiper-slide .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .swiper-slide.text-center .excerpt {
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide.image-left .container {
    -webkit-flex-direction: row-reverse;
    -webkit-justify-content: flex-start;

    flex-direction: row-reverse;
    justify-content: flex-start;
  }
  .swiper-slide.image-left .image-container {
    width: 50%;
    padding-right: 20px;
  }
  .swiper-slide.image-left .copy-container {
    width: 50%;
    padding-left: 20px;
  }

  .swiper-slide.image-right .container {
    -webkit-flex-direction: row;
    -webkit-justify-content: flex-start;

    flex-direction: row;
    justify-content: flex-start;
  }
  .swiper-slide.image-right .image-container {
    width: 50%;
    padding-left: 20px;
  }
  .swiper-slide.image-right .copy-container {
    width: 50%;
    padding-right: 20px;
  }
  .swiper-slide.text-right .excerpt {
    float: right;
  }

  .swiper-slide.image-right .copy-container + .image-container,
  .swiper-slide.image-left .copy-container + .image-container {
    margin-top: 0;
  }
}

@media (min-width: 1100px) {
  /*---------------------------*/
  /*- LAYERS BADGE ------------*/
  .created-using-layers {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 15px;
    height: 14px;
    z-index: 99;
    background: "url('/images/created-using-layers.png')" top no-repeat;
    background-size: 15px 28px;
    transition: all 0.15s ease-in-out;
  }
  .created-using-layers span {
    display: none;
  }
  .created-using-layers:after {
    padding: 4px 4px;
    position: absolute;
    bottom: -1px;
    right: 25px;
    z-index: 1;
    content: attr(tooltip);
    background: rgba(0, 157, 243, 0.85);
    color: rgba(255, 255, 255, 0.8);
    border-radius: 100px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s ease-in-out 0.1s;
    font-size: 0.8rem;
    line-height: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    white-space: nowrap;
  }
  .created-using-layers:hover {
    background-position: bottom;
  }
  .created-using-layers:hover:after {
    opacity: 1;
    visibility: visible;
    right: 20px;
  }
}

@media (max-width: 480px) and (max-device-width: 480px) and (max-width: 600px) {
  /*---------------------------*/
  /*- BACK TO TOP -------------*/
  #back-to-top {
    display: none;
  }
}
